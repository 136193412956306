/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim
*https://cors-anywhere.herokuapp.com/http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=
*http://reacttest.winnercloudstudio.com/api/mewahapicode.php?mode=
=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Dashboard from "views/Dashboard.jsx";
//import Dashboard from "views/Dashboard2.jsx";
import Profile from "views/examples/Profile.jsx";
import Orders from "views/examples/Orders.jsx";
//import Orders from "views/examples/Orders2.jsx";
import Register from "views/examples/Register.jsx";
import Login from "views/examples/Login.jsx";
import Tables from "views/examples/Tables.jsx";
import Quotation from "views/examples/Quotation.jsx";
import AddNewQuotation from "views/examples/AddNewQuotation.jsx";
import AddNewROT from "views/examples/AddNewROT.jsx";
import Forget from "views/examples/Forgetpassword.jsx";
import abc from "views/examples/Forgetpassword.jsx";
import Report from "views/examples/Report.jsx";
import filemanager from "views/examples/filemanager.jsx";

var routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-blue",
    component: Dashboard,
    layout: "/admin"
  },
 
  {
    path: "/orders",
    name: "Order",
    icon: "ni ni-collection text-blue",
    component: Orders,
    layout: "/admin"
  },
  {
    path: "/quotation",
    name: "Quotation",
    icon: "ni ni-money-coins text-blue",
    component: Quotation,
    layout: "/admin"
  },
  // {
  //   path: "/report",
  //   name: "Report",
  //   icon: "ni ni-chart-bar-32 text-blue",
  //   component: Report,
  //   layout: "/admin"
  // },
  // {
  //   path: "/filemanager",
  //   name: "File Manager",
  //   icon: "ni ni-single-copy-04 text-blue",
  //   component: filemanager,
  //   layout: "/admin"
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth"
  },
  {
    path: "/addnewquotation",
    name: "Add New Quotation",
    icon: "ni ni-money-coins text-blue",
    component: AddNewQuotation,
    layout: "/admin"
  },
  {
    path: "/addnewrot",
    name: "Add New ROT",
    icon: "ni ni-money-coins text-blue",
    component: AddNewROT,
    layout: "/admin"
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth"
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin"
  },
  {
    path: "/forgetpassword",
    name: "Forget password",
    icon: "ni ni-single-02 text-yellow",
    component: Forget,
    layout: "/auth"
  },
 
];
export default routes;
