/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';


// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Alert,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Button,
  Modal,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  FormFeedback,
  FormText,
  Label,
} from "reactstrap";
// core components
import ReactToPrint from 'react-to-print';
import { Link } from 'react-router-dom';
import Header from "components/Headers/Header.jsx";
import classnames from "classnames";
import Popup from "reactjs-popup";
import ReactDatetime from "react-datetime";
import { textSpanIntersectsWith } from "typescript";
import axios ,{post} from 'axios'; 
import Moment from 'moment';
import { controllers } from "chart.js";
//Validation date?
//export or import, also check type
//change shipmenttype then empty type


class AddNewROT extends React.Component {
  
  state = {
    attachmentsetID:0,
    tabs: 1,
    exampleModal: false,
    editpopuout:false,
    isopendropdownshipper:false,
    isopendropdownshipmenttype:false,
    isopendropdownpod:false,
    isopendropdownpol:false,
    shipmenttypeadropdowntext:'',
    isopendropdownoperator:false,
    operatoreadropdowntext:'',
    isopendropdownpickup:false,
    isopendropdownreturnyard:false,
    pickupdropdowntext:'',
    returnyarddropdowntext:'',
    type2dropdowntext:'',
    isopendropdownforwardingagent:false,
    forwardingagentdropdowntext:'',
    quotationtabledatafinal:[],
    operatorlistdata:[],
    yardlist:[],
    returnyardlist:[],
    typelist:[],
    typelistfirstone:[],
    Billtoparty:sessionStorage.getItem('Billtoparty'),
    Createdbyref:sessionStorage.getItem('Ref'),
    deliveryaddress:'',
    areatextbox:'',
    poltext:'',
    podtext:'',
    disableddropdownpol:true,
    disableddropdownpod:true,
    volumenumberinput:0,
    volumenumberinput1:0,
    volumenumberinput2:0,
    volumenumberinput3:0,
    volumenumberinput4:0,
    volumenumberinput5:0,
    volumenumberinput6:0,
    volumenumberinput7:0,
    volumenumberinput8:0,
    volumenumberinput9:0,
    type1dropdowntext:'',
    type1dropdowntext1:'',
    type1dropdowntext2:'',
    type1dropdowntext3:'',
    type1dropdowntext4:'',
    type1dropdowntext5:'',
    type1dropdowntext6:'',
    type1dropdowntext7:'',
    type1dropdowntext8:'',
    type1dropdowntext9:'',
    isopendropdowntype:false,
    isopendropdowntype1:false,
    isopendropdowntype2:false,
    isopendropdowntype3:false,
    isopendropdowntype4:false,
    isopendropdowntype5:false,
    isopendropdowntype6:false,
    isopendropdowntype7:false,
    isopendropdowntype8:false,
    isopendropdowntype9:false,
    volumeshowrow:0,
    rowvolume1:false,
    rowvolume2:false,
    rowvolume3:false,
    rowvolume4:false,
    rowvolume5:false,
    rowvolume6:false,
    rowvolume7:false,
    rowvolume8:false,
    minusbuttonshow:false,
    forwardingagentlist:[],
    aftersubmitstatus:0,
    picvalue:'',
    contactvalue:'',
    bookingno:'',
    Vsleta:'',
    closingday:'',
    emptytest:[],
    submitbuttonclick:0,
    picvalidation:false,
    contactvalidation:false,
    podtextvalidation:false,
    poltextvalidation:false,
    bookingnovalidation:false,
    vsletavalidation:false,
    closingvalidation:false,
    volumenumber1validation:false,
    volumenumber2validation:false,
    volumenumber3validation:false,
    volumenumber4validation:false,
    volumenumber5validation:false,
    volumenumber6validation:false,
    volumenumber7validation:false,
    volumenumber8validation:false,
    volumenumber9validation:false,
    volumenumber10validation:false,
    containervalidation:false,
    requestdatevalidation:false,
    vesselnamevalidation:false,
    vesselvoyagevalidation:false,
    commodityvalidation:false,
    areadropdowntext:'',
    commodity:'',
    vesselname:'',
    vesselvoyage:'',
    errorsubmit:false,
    samedate:false,
    alerterrormessage:'',
    attachmentquantity:1,
    selectedFile1: null,
    selectedFile2: null,
    selectedFile3: null,
    selectedFile4: null,
    selectedFile5: null,
    selectedFile6: null,
    selectedFile7: null,
    selectedFile8: null,
    selectedFile9: null,
    selectedFile10: null,
    container0:'',
    container1:'',
    container2:'',
    container3:'',
    container4:'',
    container5:'',
    container6:'',
    container7:'',
    requestdate0:'',
    vsltoshow:'',
    closingdateshow:'',
    numberrowaftersubmit:0,
    Createdbyaccount:sessionStorage.getItem('Ref'),
    requestdateerrorinvalid:false,
    percentupload1:'',
    podrecentlist:[],
    polrecentlist:[],
    bookingrecentlist:[],
    vesselnamerecentlist:[],
    vesselvoyagerecentlist:[],
    commodityrecentlist:[],
    bookingalllist:[],
    selectedFilename1:"",
    selectedFilename2:"",
    selectedFilename3:"",
    selectedFilename4:"",
    selectedFilename5:"",
    selectedFilename6:"",
    selectedFilename7:"",
    selectedFilename8:"",
    selectedFilename9:"",
    selectedFilename10:"",
    percentupload1:0,
    percentupload2:0,
    percentupload3:0,
    percentupload4:0,
    percentupload5:0,
    percentupload6:0,
    percentupload7:0,
    percentupload8:0,
    percentupload9:0,
    percentupload10:0,
    forwardingpicname:sessionStorage.getItem('Name'),
    linkurl:"https://crmpkl-mewahtrans.mewahserver.com/api/mewahapicode.php?",
    imageurllink:"https://crmpkl-mewahtrans.mewahserver.com/api/img/profile_img/",
    consignmentlist:[],
    containerlist:[],

    //imageurllink:"http://crmpkl-mewahtrans.mewahserver.com/img/profile_img/",
   // linkurl:"https://cors-anywhere.herokuapp.com/http://crmpkl-mewahtrans.mewahserver.com/api/mewahapicode.php?",

    
  };

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index
    });
  };
 
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };

  toggledropdownarea =  state => {
    this.setState({
      [state]:!this.state[state]
    });
   
  };

  handleChange=(event,stateid)=> {
    if(stateid === "picvalue"){
      this.setState({picvalue: event});
    } else if(stateid == "contactvalue"){
      this.setState({contactvalue: event});
    }else if(stateid == "poltext"){
      this.setState({poltext: event});
    }else if(stateid == "podtext"){
      this.setState({podtext: event});
    }else if(stateid == "bookingno"){
      this.setState({bookingno: event});
    }else if(stateid == "volumenumberinput"){
      this.setState({volumenumberinput: event});
      
    }else if(stateid == "volumenumberinput1"){
      this.setState({volumenumberinput1: event});
      console.log("volumenumberinput1: "+ this.state.volumenumberinput1)
    }else if(stateid == "volumenumberinput2"){
      this.setState({volumenumberinput2: event});
      console.log("volumenumber2value2: "+ this.state.volumenumberinput2)
    }else if(stateid == "volumenumberinput3"){
      this.setState({volumenumberinput3: event});
    }else if(stateid == "volumenumberinput4"){
      this.setState({volumenumberinput4: event});
    }else if(stateid == "volumenumberinput5"){
      this.setState({volumenumberinput5: event});
    }else if(stateid == "volumenumberinput6"){
      this.setState({volumenumberinput6: event});
    }else if(stateid == "volumenumberinput7"){
      this.setState({volumenumberinput7: event});
    }else if(stateid == "volumenumberinput8"){
      this.setState({volumenumberinput8: event});
    }else if(stateid == "volumenumberinput9"){
      this.setState({volumenumberinput9: event});
    }
    
    
  }

  componentDidMount(){
    this.setState({aftersubmitstatus:3})
    this.getdetailquotationtableapi();
    this.getoperatorlistapi();
    this.getpickupyardreturnyardlist();
    this.getforwardingagentlist();
    this.getrecentautocomplete();
    this.getbookingnoprevious();
    this.setState({attachmentsetID:Moment().unix()}) ;   
  }


  refreshallthing =(numberrow) =>{
      window.location.reload()
  //   for(let i=0;i<numberrow;i++){
          
  //       this.setState({["container" + i] :''})
  //       this.setState({["requestdatedisable"+i] :false})
  //       this.setState({["requestdate" + i] :new Date().getTime()})
        
     
  //       this.setState({["Seal" +i] :''})
       
      
  //       this.setState({["checktbe"+i] :false})
       
  //     console.log("numberrow: "+numberrow);
  //     console.log("container: "+this.state["container" + i]);
  //     console.log("requestdate: "+this.state["requestdate" + i]);
  //     console.log("checktbe: "+this.state["checktbe" + i]);
  //   }

    
  //   this.setState({tabs: 1,
  //   exampleModal: false,
  //   editpopuout:false,
  //   isopendropdownshipper:false,
  //   isopendropdownshipmenttype:false,
  //   isopendropdownpod:false,
  //   isopendropdownpol:false,
  //   shipmenttypeadropdowntext:'',
  //   isopendropdownoperator:false,
  //   operatoreadropdowntext:'',
  //   isopendropdownpickup:false,
  //   isopendropdownreturnyard:false,
  //   pickupdropdowntext:'',
  //   returnyarddropdowntext:'',
  //   type2dropdowntext:'',
  //   isopendropdownforwardingagent:false,
  //   forwardingagentdropdowntext:'',
  
  //   Billtoparty:sessionStorage.getItem('Billtoparty'),
  //   deliveryaddress:'',
  //   areatextbox:'',
  //   poltext:'',
  //   podtext:'',
  //   disableddropdownpol:true,
  //   disableddropdownpod:true,
  //   volumenumberinput:0,
  //   volumenumberinput1:0,
  //   volumenumberinput2:0,
  //   volumenumberinput3:0,
  //   volumenumberinput4:0,
  //   volumenumberinput5:0,
  //   volumenumberinput6:0,
  //   volumenumberinput7:0,
  //   volumenumberinput8:0,
  //   volumenumberinput9:0,
  //   type1dropdowntext:'',
  //   type1dropdowntext1:'',
  //   type1dropdowntext2:'',
  //   type1dropdowntext3:'',
  //   type1dropdowntext4:'',
  //   type1dropdowntext5:'',
  //   type1dropdowntext6:'',
  //   type1dropdowntext7:'',
  //   type1dropdowntext8:'',
  //   type1dropdowntext9:'',
  //   isopendropdowntype:false,
  //   isopendropdowntype1:false,
  //   isopendropdowntype2:false,
  //   isopendropdowntype3:false,
  //   isopendropdowntype4:false,
  //   isopendropdowntype5:false,
  //   isopendropdowntype6:false,
  //   isopendropdowntype7:false,
  //   isopendropdowntype8:false,
  //   isopendropdowntype9:false,
  //   volumeshowrow:0,
  //   rowvolume1:false,
  //   rowvolume2:false,
  //   rowvolume3:false,
  //   rowvolume4:false,
  //   rowvolume5:false,
  //   rowvolume6:false,
  //   rowvolume7:false,
  //   rowvolume8:false,
  //   minusbuttonshow:false,
  //   picvalue:'',
  //   contactvalue:'',
  //   bookingno:'',
  //   Vsleta:'',
  //   closingday:'',
  //   submitbuttonclick:0,
  //   picvalidation:false,
  //   contactvalidation:false,
  //   podtextvalidation:false,
  //   poltextvalidation:false,
  //   bookingnovalidation:false,
  //   vsletavalidation:false,
  //   closingvalidation:false,
  //   volumenumber1validation:false,
  //   volumenumber2validation:false,
  //   volumenumber3validation:false,
  //   volumenumber4validation:false,
  //   volumenumber5validation:false,
  //   volumenumber6validation:false,
  //   volumenumber7validation:false,
  //   volumenumber8validation:false,
  //   volumenumber9validation:false,
  //   volumenumber10validation:false,
  //   containervalidation:false,
  //   requestdatevalidation:false,
  //   vesselnamevalidation:false,
  //   vesselvoyagevalidation:false,
  //   commodityvalidation:false,
  //   areadropdowntext:'',
  //   commodity:'',
  //   vesselname:'',
  //   vesselvoyage:'',
  //   errorsubmit:false,
  //   aftersubmitstatus:0,
  // })




  // this.getrecentautocomplete()

  }
  
  submitnewrotforpending =() =>{ 
//2

if(window.confirm("Ready to submit this Rot?")){
    
   
  var errorornot = false;
   
  let numberrow = Number(this.state.volumenumberinput1)+Number(this.state.volumenumberinput2) + 
  Number(this.state.volumenumberinput3)+Number(this.state.volumenumberinput4) +
  Number(this.state.volumenumberinput5)+Number(this.state.volumenumberinput6) +
  Number(this.state.volumenumberinput7)+Number(this.state.volumenumberinput8) +
  Number(this.state.volumenumberinput9)+Number(this.state.volumenumberinput) 

  

  if(this.state.percentupload1 == 100 || this.state.percentupload1 == 0){     
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})      
  }

  if(this.state.percentupload2 == 100 || this.state.percentupload2 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload3 == 100 || this.state.percentupload3 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload4 == 100 || this.state.percentupload4 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload5 == 100 || this.state.percentupload5 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload6 == 100 || this.state.percentupload6 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload7 == 100 || this.state.percentupload7 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload8 == 100 || this.state.percentupload8 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload9 == 100 || this.state.percentupload9 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }

  if(this.state.percentupload10 == 100 || this.state.percentupload10 == 0){
  }else{
    errorornot=true;
    this.setState({alerterrormessage:'Please wait attachment upload'})
  }





    for(let i=0;i<numberrow;i++){
      //console.log("Requestdate: " + this.state["requestdate"+i])
     // console.log("requestdatedisable: " + this.state["requestdatedisable"+i])
     // console.log("checktbe: " + this.state["checktbe"+i])
     
    }
  if(this.state.volumenumberinput == 0){
    this.setState({volumenumber1validation:true});
    errorornot=true;
  }else{
    this.setState({volumenumber1validation:false});
   
  }

  if(this.state.type1dropdowntext == ''){
    this.setState({type1validation:true});
    this.setState({type1validationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({type1validation:false});
    this.setState({type1validationmessage:""});
  }

  if(this.state.volumenumberinput1 == 0 && this.state.type1dropdowntext1 != ''){
    this.setState({volumenumber2validation:true});
    errorornot=true;
  }else{
    this.setState({volumenumber2validation:false});
  }

  if(this.state.type1dropdowntext == '' && this.state.volumenumberinput1 != 0){
    this.setState({type2validation:true});
    this.setState({type2validationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({type2validation:false});
    this.setState({type2validationmessage:""});
  }
  if(this.state.rowvolume1==true){
    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput1 == 0){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput2 == 0){
      this.setState({volumenumber3validation:true});
      errorornot=true;
    }

    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
    }

    if(this.state.type1dropdowntext1 == ''){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }

    if(this.state.type1dropdowntext2 == ''){
      this.setState({type3validation:true});
      this.setState({type3validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type3validation:false});
      this.setState({type3validationmessage:""});
    }
  }

  if(this.state.rowvolume2==true){
    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput1 == 0){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput2 == 0){
      this.setState({volumenumber3validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput3 == 0){
      this.setState({volumenumber4validation:true});
      errorornot=true;
    }
    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
    }

    if(this.state.type1dropdowntext1 == ''){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }

    if(this.state.type1dropdowntext2 == ''){
      this.setState({type3validation:true});
      this.setState({type3validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type3validation:false});
      this.setState({type3validationmessage:""});
    }

    if(this.state.type1dropdowntext3 == ''){
      this.setState({type4validation:true});
      this.setState({type4validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type4validation:false});
      this.setState({type4validationmessage:""});
    }
  }

  if(this.state.rowvolume3==true){
    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput1 == 0){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput2 == 0){
      this.setState({volumenumber3validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput3 == 0){
      this.setState({volumenumber4validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput4 == 0){
      this.setState({volumenumber5validation:true});
      errorornot=true;
    }



    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
    }

    if(this.state.type1dropdowntext1 == ''){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }

    if(this.state.type1dropdowntext2 == ''){
      this.setState({type3validation:true});
      this.setState({type3validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type3validation:false});
      this.setState({type3validationmessage:""});
    }

    if(this.state.type1dropdowntext3 == ''){
      this.setState({type4validation:true});
      this.setState({type4validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type4validation:false});
      this.setState({type4validationmessage:""});
    }
    if(this.state.type1dropdowntext4 == ''){
      this.setState({type5validation:true});
      this.setState({type5validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type5validation:false});
      this.setState({type5validationmessage:""});
    }
  }

  if(this.state.rowvolume4==true){
    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput1 == 0){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput2 == 0){
      this.setState({volumenumber3validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput3 == 0){
      this.setState({volumenumber4validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput4 == 0){
      this.setState({volumenumber5validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput5 == 0){
      this.setState({volumenumber6validation:true});
      errorornot=true;
    }



    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
    }

    if(this.state.type1dropdowntext1 == ''){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }

    if(this.state.type1dropdowntext2 == ''){
      this.setState({type3validation:true});
      this.setState({type3validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type3validation:false});
      this.setState({type3validationmessage:""});
    }

    if(this.state.type1dropdowntext3 == ''){
      this.setState({type4validation:true});
      this.setState({type4validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type4validation:false});
      this.setState({type4validationmessage:""});
    }
    if(this.state.type1dropdowntext4 == ''){
      this.setState({type5validation:true});
      this.setState({type5validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type5validation:false});
      this.setState({type5validationmessage:""});
    }
    if(this.state.type1dropdowntext5 == ''){
      this.setState({type6validation:true});
      this.setState({type6validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type6validation:false});
      this.setState({type6validationmessage:""});
    }
  }

  if(this.state.rowvolume5==true){
    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput1 == 0){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput2 == 0){
      this.setState({volumenumber3validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput3 == 0){
      this.setState({volumenumber4validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput4 == 0){
      this.setState({volumenumber5validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput5 == 0){
      this.setState({volumenumber6validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput6 == 0){
      this.setState({volumenumber7validation:true});
      errorornot=true;
    }

    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
    }

    if(this.state.type1dropdowntext1 == ''){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }

    if(this.state.type1dropdowntext2 == ''){
      this.setState({type3validation:true});
      this.setState({type3validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type3validation:false});
      this.setState({type3validationmessage:""});
    }

    if(this.state.type1dropdowntext3 == ''){
      this.setState({type4validation:true});
      this.setState({type4validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type4validation:false});
      this.setState({type4validationmessage:""});
    }
    if(this.state.type1dropdowntext4 == ''){
      this.setState({type5validation:true});
      this.setState({type5validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type5validation:false});
      this.setState({type5validationmessage:""});
    }
    if(this.state.type1dropdowntext5 == ''){
      this.setState({type6validation:true});
      this.setState({type6validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type6validation:false});
      this.setState({type6validationmessage:""});
    }
    if(this.state.type1dropdowntext6 == ''){
      this.setState({type7validation:true});
      this.setState({type7validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type7validation:false});
      this.setState({type7validationmessage:""});
    }
  }

  if(this.state.rowvolume6==true){
    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput1 == 0){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput2 == 0){
      this.setState({volumenumber3validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput3 == 0){
      this.setState({volumenumber4validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput4 == 0){
      this.setState({volumenumber5validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput5 == 0){
      this.setState({volumenumber6validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput6 == 0){
      this.setState({volumenumber7validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput7 == 0){
      this.setState({volumenumber8validation:true});
      errorornot=true;
    }


    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
      
    }

    if(this.state.type1dropdowntext1 == ''){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }

    if(this.state.type1dropdowntext2 == ''){
      this.setState({type3validation:true});
      this.setState({type3validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type3validation:false});
      this.setState({type3validationmessage:""});
    }

    if(this.state.type1dropdowntext3 == ''){
      this.setState({type4validation:true});
      this.setState({type4validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type4validation:false});
      this.setState({type4validationmessage:""});
    }
    if(this.state.type1dropdowntext4 == ''){
      this.setState({type5validation:true});
      this.setState({type5validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type5validation:false});
      this.setState({type5validationmessage:""});
    }
    if(this.state.type1dropdowntext5 == ''){
      this.setState({type6validation:true});
      this.setState({type6validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type6validation:false});
      this.setState({type6validationmessage:""});
    }
    if(this.state.type1dropdowntext6 == ''){
      this.setState({type7validation:true});
      this.setState({type7validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type7validation:false});
      this.setState({type7validationmessage:""});
    }
    if(this.state.type1dropdowntext7 == ''){
      this.setState({type8validation:true});
      this.setState({type8validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type8validation:false});
      this.setState({type8validationmessage:""});
    }
  }

  if(this.state.rowvolume7==true){
    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput1 == 0){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput2 == 0){
      this.setState({volumenumber3validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput3 == 0){
      this.setState({volumenumber4validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput4 == 0){
      this.setState({volumenumber5validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput5 == 0){
      this.setState({volumenumber6validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput6 == 0){
      this.setState({volumenumber7validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput7 == 0){
      this.setState({volumenumber8validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput8 == 0){
      this.setState({volumenumber9validation:true});
      errorornot=true;
    }


    
    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
    }

    if(this.state.type1dropdowntext1 == ''){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }

    if(this.state.type1dropdowntext2 == ''){
      this.setState({type3validation:true});
      this.setState({type3validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type3validation:false});
      this.setState({type3validationmessage:""});
    }

    if(this.state.type1dropdowntext3 == ''){
      this.setState({type4validation:true});
      this.setState({type4validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type4validation:false});
      this.setState({type4validationmessage:""});
    }
    if(this.state.type1dropdowntext4 == ''){
      this.setState({type5validation:true});
      this.setState({type5validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type5validation:false});
      this.setState({type5validationmessage:""});
    }
    if(this.state.type1dropdowntext5 == ''){
      this.setState({type6validation:true});
      this.setState({type6validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type6validation:false});
      this.setState({type6validationmessage:""});
    }
    if(this.state.type1dropdowntext6 == ''){
      this.setState({type7validation:true});
      this.setState({type7validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type7validation:false});
      this.setState({type7validationmessage:""});
    }
    if(this.state.type1dropdowntext7 == ''){
      this.setState({type8validation:true});
      this.setState({type8validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type8validation:false});
      this.setState({type8validationmessage:""});
    }
    if(this.state.type1dropdowntext8 == ''){
      this.setState({type9validation:true});
      this.setState({type9validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type9validation:false});
      this.setState({type9validationmessage:""});
    }
  }

  if(this.state.rowvolume8==true){
    if(this.state.volumenumberinput == 0){
      this.setState({volumenumber1validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput1 == 0){
      this.setState({volumenumber2validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput2 == 0){
      this.setState({volumenumber3validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput3 == 0){
      this.setState({volumenumber4validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput4 == 0){
      this.setState({volumenumber5validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput5 == 0){
      this.setState({volumenumber6validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput6 == 0){
      this.setState({volumenumber7validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput7 == 0){
      this.setState({volumenumber8validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput8 == 0){
      this.setState({volumenumber9validation:true});
      errorornot=true;
    }
    if(this.state.volumenumberinput9 == 0){
      this.setState({volumenumber10validation:true});
      errorornot=true;
    }



    if(this.state.type1dropdowntext == ''){
      this.setState({type1validation:true});
      this.setState({type1validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type1validation:false});
      this.setState({type1validationmessage:""});
    }

    if(this.state.type1dropdowntext1 == ''){
      this.setState({type2validation:true});
      this.setState({type2validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type2validation:false});
      this.setState({type2validationmessage:""});
    }

    if(this.state.type1dropdowntext2 == ''){
      this.setState({type3validation:true});
      this.setState({type3validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type3validation:false});
      this.setState({type3validationmessage:""});
    }

    if(this.state.type1dropdowntext3 == ''){
      this.setState({type4validation:true});
      this.setState({type4validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type4validation:false});
      this.setState({type4validationmessage:""});
    }
    if(this.state.type1dropdowntext4 == ''){
      this.setState({type5validation:true});
      this.setState({type5validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type5validation:false});
      this.setState({type5validationmessage:""});
    }
    if(this.state.type1dropdowntext5 == ''){
      this.setState({type6validation:true});
      this.setState({type6validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type6validation:false});
      this.setState({type6validationmessage:""});
    }
    if(this.state.type1dropdowntext6 == ''){
      this.setState({type7validation:true});
      this.setState({type7validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type7validation:false});
      this.setState({type7validationmessage:""});
    }
    if(this.state.type1dropdowntext7 == ''){
      this.setState({type8validation:true});
      this.setState({type8validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type8validation:false});
      this.setState({type8validationmessage:""});
    }
    if(this.state.type1dropdowntext8 == ''){
      this.setState({type9validation:true});
      this.setState({type9validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type9validation:false});
      this.setState({type9validationmessage:""});
    }
    if(this.state.type1dropdowntext9 == ''){
      this.setState({type10validation:true});
      this.setState({type10validationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({type10validation:false});
      this.setState({type10validationmessage:""});
    }
  }


  if(this.state.areadropdowntext ==''){
    this.setState({shippervalidation:true});
    this.setState({shippervalidationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({shippervalidation:false});
    this.setState({shippervalidationmessage:""});
  }

  if(this.state.operatoreadropdowntext ==''){
    this.setState({operationvalidation:true});
    this.setState({operationvalidationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({operationvalidation:false});
    this.setState({operationvalidationmessage:""});
  }

  if(this.state.pickupdropdowntext ==''){
    this.setState({pickupvalidation:true});
    this.setState({pickupvalidationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({pickupvalidation:false});
    this.setState({pickupvalidationmessage:""});
  }

  if(this.state.shipmenttypeadropdowntext =="Import"){
    if(this.state.returnyarddropdowntext ==''){
      this.setState({returnvalidation:true});
      this.setState({returnvalidationmessage:"Please fill in the detail"});
      errorornot=true;
    }else{
      this.setState({returnvalidation:false});
      this.setState({returnvalidationmessage:""});
    }
  }

  if(this.state.forwardingagentdropdowntext ==''){
    this.setState({forwardingvalidation:true});
    this.setState({forwardingvalidationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({forwardingvalidation:false});
    this.setState({forwardingvalidationmessage:""});
  }

  

  if(this.state.shipmenttypeadropdowntext ==''){
    this.setState({shipmenttypevalidation:true});
    this.setState({shipmenttypevalidationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({shipmenttypevalidation:false});
    this.setState({shipmenttypevalidationmessage:""});
  }

  if(this.state.podtext ==''){
    this.setState({podtextvalidation:true});
    this.setState({podtextvalidationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({podtextvalidation:false});
    this.setState({podtextvalidationmessage:""});
  }

  if(this.state.poltext ==''){
    this.setState({poltextvalidation:true});
    this.setState({poltextvalidationmessage:"Please fill in the detail"});
    errorornot=true;
  }else{
    this.setState({poltextvalidation:false});
    this.setState({poltextvalidationmessage:""});
  }

 

  if(this.state.picvalue==''){
    this.setState({picvalidation:true});
    errorornot=true;
  }else{
    this.setState({picvalidation:false});
  }

  if(this.state.contactvalue==''){
    this.setState({contactvalidation:true});
    errorornot=true;
  }else{
    this.setState({contactvalidation:false});
  }
  
  if(this.state.bookingno==''){
    this.setState({bookingnovalidation:true});
    errorornot=true;
  }else{
    this.setState({bookingnovalidation:false});
  }
  
  if(this.state.Vsleta==''){
    this.setState({vsletavalidation:true});
    errorornot=true;
  }else{
    this.setState({vsletavalidation:false});
  }
  
  if(this.state.closingday==''){
    this.setState({closingvalidation:true});
    errorornot=true;
  }else{
    this.setState({closingvalidation:false});
  }
    


  if(this.state.vesselname==''){
    this.setState({vesselnamevalidation:true});
    this.setState({vesselnamevalidationmesssage:"Please fill in the detail"});
    errorornot=true;    
  }else{
    this.setState({vesselnamevalidation:false});
    this.setState({vesselnamevalidationmesssage:""});
  }
  
  if(this.state.vesselvoyage==''){
    this.setState({vesselvoyagevalidation:true});
    this.setState({vesselvoyagevalidationmessage:"Please fill in the detail"});
    errorornot=true;  
   
  }else{
    this.setState({vesselvoyagevalidation:false});
    this.setState({vesselvoyagevalidationmessage:""});
  }  
   

  if(errorornot == true){
    this.setState({alerterrormessage:'Something wrong, Please fill in all details'})
  }

  if(this.state.shipmenttypeadropdowntext=="Import"){
    //CONTAINER NUMBER MUST FILL
   
    for(let i=0;i<numberrow;i++){
      //console.log("container:" + this.state["container" + i])
      if(this.state["container" + i] =='' || this.state["container" + i] == null ){
        errorornot = true
        this.setState({alerterrormessage:'Please fill in Container No'})
      }  
      if(this.state["requestdate" + i] == ''|| this.state["requestdate" + i] == null){
        errorornot = true
        this.setState({alerterrormessage:'Request Date must fill in'})
        this.setState({requestdateerrorinvalid:true});
      } 
      if(this.state["requestdate" + i] != 946684800){
        if(this.state["requestdate" + i] < this.state.Vsleta){
          errorornot = true
          this.setState({alerterrormessage:'Request Date cannot before ETA Date'})
        } 
      }
     
    }
  }else if(this.state.shipmenttypeadropdowntext=="Export"){
    //REQUEST DATE CANNOT AFTER CLOSING DATE, if(requestdate > closingday)false
    for(let i=0;i<numberrow;i++){
      if(this.state["requestdate" + i] > this.state.closingday){
        errorornot = true
        this.setState({alerterrormessage:'Request Date cannot be after Closing Date'})
      }  
     
      if(this.state["requestdate" + i] > this.state.Vsleta){
        errorornot = true
        this.setState({alerterrormessage:'Request Date must before ETA Date'})
      } 
      if(this.state["requestdate" + i] == ''|| this.state["requestdate" + i] == null){
        errorornot = true
        this.setState({alerterrormessage:'Request Date must fill in'})
        this.setState({requestdateerrorinvalid:true})
      } 
       
      // if(this.state["requestdate" + i] == 946684800){
      //   errorornot = true
      //   this.setState({alerterrormessage:'Request Date cannot be TBC when export'})
      // } 
    }
  }

  if(this.state.shipmenttypeadropdowntext == 'Export' && this.state.selectedFilename1 == null){
    if(window.confirm("Are you sure submit without attachment when export?")){

    }else{
      errorornot=true;
    }

  }

  // console.log("type1: " + this.state.type1dropdowntext)
  // console.log("type2: " + this.state.type1dropdowntext1)
  // console.log("type3: " + this.state.type1dropdowntext2)
  // console.log("type4: " + this.state.type1dropdowntext3)
  // console.log("type5: " + this.state.type1dropdowntext4)
  // console.log("type6: " + this.state.type1dropdowntext5)
  // console.log("type7: " + this.state.type1dropdowntext6)
  // console.log("type8: " + this.state.type1dropdowntext7)
  // console.log("type9: " + this.state.type1dropdowntext8)

  var bookingalllistnumber = Object.keys(this.state.bookingalllist).length;
  for(let i=0;i<bookingalllistnumber;i++){
      if(this.state.bookingalllist[i].title==this.state.bookingno){
        errorornot=true;
        window.scrollTo(0, 0)
        this.setState({errorsubmit:true})
        this.setState({alerterrormessage:'Booking no is used before'})
        return;
      }else{
        
      }      
  } 

  


  if( errorornot==true){
    window.scrollTo(0, 0)
    this.setState({errorsubmit:true})
    
  }else{
    
    
      //console.log("clicked")
      this.setState({aftersubmitstatus:3})
      var urlencoded = new URLSearchParams();
      urlencoded.append("billtoparty", this.state.Billtoparty);
      urlencoded.append("shipper",this.state.areadropdowntext );
      urlencoded.append("deliveryaddress", this.state.deliveryaddress);
      urlencoded.append("areatextbox", this.state.areatextbox);
      urlencoded.append("picvalue", this.state.picvalue);
      urlencoded.append("contact", this.state.contactvalue);
  
      //shipment type problem
      urlencoded.append("shipmenttypedropdowntext", this.state.shipmenttypeadropdowntext);
      urlencoded.append("poltext", this.state.poltext);
      urlencoded.append("podtext", this.state.podtext);
      urlencoded.append("bookingno", this.state.bookingno);

      if(this.state.attachmentquantity == 1){
        urlencoded.append("Filename1", this.state.selectedFilename1);
      urlencoded.append("Filename2", "");
      urlencoded.append("Filename3", "");
      urlencoded.append("Filename4", "");
      urlencoded.append("Filename5", "");
      urlencoded.append("Filename6", "");
      urlencoded.append("Filename7", "");
      urlencoded.append("Filename8", "");
      urlencoded.append("Filename9", "");
      urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 2){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
      urlencoded.append("Filename3", "");
      urlencoded.append("Filename4", "");
      urlencoded.append("Filename5", "");
      urlencoded.append("Filename6", "");
      urlencoded.append("Filename7", "");
      urlencoded.append("Filename8", "");
      urlencoded.append("Filename9", "");
      urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 3){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
        urlencoded.append("Filename3", this.state.selectedFilename3);
        urlencoded.append("Filename4", "");
        urlencoded.append("Filename5", "");
        urlencoded.append("Filename6", "");
        urlencoded.append("Filename7", "");
        urlencoded.append("Filename8", "");
        urlencoded.append("Filename9", "");
        urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 4){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
        urlencoded.append("Filename3", this.state.selectedFilename3);
        urlencoded.append("Filename4", this.state.selectedFilename4);
        urlencoded.append("Filename5", "");
        urlencoded.append("Filename6", "");
        urlencoded.append("Filename7", "");
        urlencoded.append("Filename8", "");
        urlencoded.append("Filename9", "");
        urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 5){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
        urlencoded.append("Filename3", this.state.selectedFilename3);
        urlencoded.append("Filename4", this.state.selectedFilename4);
        urlencoded.append("Filename5", this.state.selectedFilename5);
        urlencoded.append("Filename6", "");
        urlencoded.append("Filename7", "");
        urlencoded.append("Filename8", "");
        urlencoded.append("Filename9", "");
        urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 6){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
        urlencoded.append("Filename3", this.state.selectedFilename3);
        urlencoded.append("Filename4", this.state.selectedFilename4);
        urlencoded.append("Filename5", this.state.selectedFilename5);
        urlencoded.append("Filename6", this.state.selectedFilename6);
        urlencoded.append("Filename7", "");
        urlencoded.append("Filename8", "");
        urlencoded.append("Filename9", "");
        urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 7){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
        urlencoded.append("Filename3", this.state.selectedFilename3);
        urlencoded.append("Filename4", this.state.selectedFilename4);
        urlencoded.append("Filename5", this.state.selectedFilename5);
        urlencoded.append("Filename6", this.state.selectedFilename6);
        urlencoded.append("Filename7", this.state.selectedFilename7);
        urlencoded.append("Filename8", "");
        urlencoded.append("Filename9", "");
        urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 8){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
        urlencoded.append("Filename3", this.state.selectedFilename3);
        urlencoded.append("Filename4", this.state.selectedFilename4);
        urlencoded.append("Filename5", this.state.selectedFilename5);
        urlencoded.append("Filename6", this.state.selectedFilename6);
        urlencoded.append("Filename7", this.state.selectedFilename7);
        urlencoded.append("Filename8", this.state.selectedFilename8);
        urlencoded.append("Filename9", "");
        urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 9){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
        urlencoded.append("Filename3", this.state.selectedFilename3);
        urlencoded.append("Filename4", this.state.selectedFilename4);
        urlencoded.append("Filename5", this.state.selectedFilename5);
        urlencoded.append("Filename6", this.state.selectedFilename6);
        urlencoded.append("Filename7", this.state.selectedFilename7);
        urlencoded.append("Filename8", this.state.selectedFilename8);
        urlencoded.append("Filename9", this.state.selectedFilename9);
        urlencoded.append("Filename10", "");
      }else if(this.state.attachmentquantity == 10){
        urlencoded.append("Filename1", this.state.selectedFilename1);
        urlencoded.append("Filename2", this.state.selectedFilename2);
        urlencoded.append("Filename3", this.state.selectedFilename3);
        urlencoded.append("Filename4", this.state.selectedFilename4);
        urlencoded.append("Filename5", this.state.selectedFilename5);
        urlencoded.append("Filename6", this.state.selectedFilename6);
        urlencoded.append("Filename7", this.state.selectedFilename7);
        urlencoded.append("Filename8", this.state.selectedFilename8);
        urlencoded.append("Filename9", this.state.selectedFilename9);
        urlencoded.append("Filename10", this.state.selectedFilename10);
      }
      


      var vsletapassup = new Date(this.state.Vsleta).getTime()/1000;
      var vsldate = new Date(this.state.Vsleta).getDate();
      var vsldatemonth = new Date(this.state.Vsleta).getMonth() + 1;
      var vslfullyear = new Date(this.state.Vsleta).getFullYear();
      var vsltotal = vsldate + "/" + vsldatemonth + "/" + vslfullyear;
      // console.log("vslfullyear: " + vslfullyear)
      // console.log("vsldate: " + vsldate)
      // console.log("vsldatemonth: " + vsldatemonth)
      // console.log("vsltotal: " + vsltotal)
      this.setState({vsltoshow:vsltotal});
      urlencoded.append("Vsleta", vsletapassup);
  
      var closingdate = new Date(this.state.closingday).getDate();
      var closingmonth = new Date(this.state.closingday).getMonth() + 1;
      var closingfullyear = new Date(this.state.closingday).getFullYear();
      var closingtotal = closingdate + "/" + closingmonth + "/" + closingfullyear;
      console.log("closingfullyear: " + closingfullyear)
      console.log("closingdate: " + closingdate)
      console.log("closingmonth: " + closingmonth)
      console.log("closingtotal: " + closingtotal)
      this.setState({closingdateshow:closingtotal});
      
      var closingdaypasssup = new Date(this.state.closingday).getTime()/1000;
      urlencoded.append("closingday", closingdaypasssup);
      urlencoded.append("operatoreadropdowntext",this.state.operatoreadropdowntext);
      urlencoded.append("pickupdropdowntext", this.state.pickupdropdowntext);
      if(this.state.shipmenttypeadropdowntext =="Export"){
        urlencoded.append("returnyarddropdowntext", "");
      }else if(this.state.shipmenttypeadropdowntext =="Import"){
        urlencoded.append("returnyarddropdowntext", this.state.returnyarddropdowntext);
      }
      
      //till here, need to add volume and check volume key in problem
      if(this.state.volumenumberinput != 0) {
        if(this.state.type1dropdowntext == "None"){
        
        } else{
          urlencoded.append("volumenumberinput", this.state.volumenumberinput);
          urlencoded.append("type1dropdowntext",this.state.type1dropdowntext );
        }
      }else{
        //alert
      }
      console.log("volumenumber1value1: "+ this.state.volumenumberinput)
    
      if(this.state.volumenumberinput1 != 0) {
        if(this.state.type1dropdowntext1 == "None"){
        
        } else{
        urlencoded.append("volumenumberinput1", this.state.volumenumberinput1);
        urlencoded.append("type1dropdowntext1", this.state.type1dropdowntext1);
        }
      }else{
        urlencoded.append("volumenumberinput1", 0);
      }
      console.log("volumenumber2value2: "+ this.state.volumenumberinput1)
  
      if(this.state.volumenumberinput2 != 0 && this.state.rowvolume1 == true) {
        if(this.state.type1dropdowntext2 == "None"){
        
        } else{
        urlencoded.append("volumenumberinput2", this.state.volumenumberinput2);
        urlencoded.append("type1dropdowntext2",this.state.type1dropdowntext2 );
        }
        console.log("consoled"+ this.state.volumenumberinput2)
      }else{
        urlencoded.append("volumenumberinput2", 0);
      }
  
      if(this.state.volumenumberinput3 != 0 && this.state.rowvolume2 == true) {
        if(this.state.type1dropdowntext3 == "None"){
        
        } else{
        urlencoded.append("volumenumberinput3", this.state.volumenumberinput3);
        urlencoded.append("type1dropdowntext3",this.state.type1dropdowntext3 );
        }
      }else{
        urlencoded.append("volumenumberinput3", 0);
      }
  
      if(this.state.volumenumberinput4 != 0 && this.state.rowvolume3 == true) {
        if(this.state.type1dropdowntext4 == "None"){
        
        } else{
        urlencoded.append("volumenumberinput4", this.state.volumenumberinput4);
        urlencoded.append("type1dropdowntext4",this.state.type1dropdowntext4 );
        }
      }else{
        urlencoded.append("volumenumberinput4", 0);
      }
  
      if(this.state.volumenumberinput5 != 0 && this.state.rowvolume4 == true) {
        if(this.state.type1dropdowntext5 == "None"){
        
        } else{
        urlencoded.append("volumenumberinput5", this.state.volumenumberinput5);
        urlencoded.append("type1dropdowntext5",this.state.type1dropdowntext5 );
        }
      }else{
        urlencoded.append("volumenumberinput5", 0);
      }
  
      if(this.state.volumenumberinput6 != 0 && this.state.rowvolume5 == true) {
        if(this.state.type1dropdowntext6 == "None"){          
        } else{
          urlencoded.append("volumenumberinput6", this.state.volumenumberinput6);
          urlencoded.append("type1dropdowntext6",this.state.type1dropdowntext6 );
        }
      }else{
        urlencoded.append("volumenumberinput6", 0);
      }
  
      if(this.state.volumenumberinput7 != 0 && this.state.rowvolume6 == true) {
        if(this.state.type1dropdowntext7 == "None"){          
        } else{
          urlencoded.append("volumenumberinput7", this.state.volumenumberinput7);
          urlencoded.append("type1dropdowntext7",this.state.type1dropdowntext7 );
        }
      }else{
        urlencoded.append("volumenumberinput7", 0);
      }
  
      if(this.state.volumenumberinput8 != 0 && this.state.rowvolume7 == true) {
        if(this.state.type1dropdowntext8 == "None"){          
        } else{
          urlencoded.append("volumenumberinput8", this.state.volumenumberinput8);
          urlencoded.append("type1dropdowntext8",this.state.type1dropdowntext8 );
        }
      }else{
        urlencoded.append("volumenumberinput8", 0);
      }
  
      if(this.state.volumenumberinput9 != 0 && this.state.rowvolume8 == true) {
        if(this.state.type1dropdowntext9 == "None"){          
        } else{
          urlencoded.append("volumenumberinput9", this.state.volumenumberinput9);
          urlencoded.append("type1dropdowntext9",this.state.type1dropdowntext9 );
        }
      }else{
        urlencoded.append("volumenumberinput9", 0);
      }
    
      
      let numberrow = Number(this.state.volumenumberinput1)+Number(this.state.volumenumberinput2) + 
      Number(this.state.volumenumberinput3)+Number(this.state.volumenumberinput4) +
      Number(this.state.volumenumberinput5)+Number(this.state.volumenumberinput6) +
      Number(this.state.volumenumberinput7)+Number(this.state.volumenumberinput8) +
      Number(this.state.volumenumberinput9)+Number(this.state.volumenumberinput) 
      
      urlencoded.append("totalnumberofjob", numberrow);
      for (let i=0;i<numberrow;i++){
        urlencoded.append("consigment"+i, i+1);
        urlencoded.append("container"+i, this.state["container" +i]);
        if(this.state["Seal" +i] == null){
          urlencoded.append("Seal"+i, " ");
        }else{
          urlencoded.append("Seal"+i, this.state["Seal" +i]);
        }

        

      

        if(this.state["container" +i] == null){
          urlencoded.append("container"+i, " ");
        }else{
          urlencoded.append("container"+i, this.state["container" +i]);
        }


        var requestdatedate = new Date(this.state["requestdate" +i]).getDate();
        var requestdatemonth = new Date(this.state["requestdate" +i]).getMonth() + 1;
        var requestdateyear = new Date(this.state["requestdate" +i]).getFullYear();
        var requestdatetotal = requestdatedate + "/" + requestdatemonth + "/" + requestdateyear;
  
        if(this.state["requestdate" +i]==946684800-28800 || this.state["requestdate" +i]==946684800){
          this.setState({["requestdatetoshow" + i]:'TBC'})
        }else{
          this.setState({["requestdatetoshow" + i]:requestdatetotal})
        }
        
        var requestdate = new Date( this.state["requestdate" +i]).getTime()/1000;
  
        console.log(i);
        console.log(this.state["container" +i]);
        console.log(this.state["Seal" +i]);
        console.log("requestdatestate: " + this.state["requestdate" +i]);
        console.log("requestdate: " + requestdate)
  
        if(this.state["requestdate" +i] == 946684800 || this.state["requestdate" +i] == 'TBC'){
          requestdatetotal ='TBC'
          urlencoded.append("requestdate"+i, this.state["requestdate" +i]);
        }else{
          urlencoded.append("requestdate"+i,requestdate);
        }

        if(this.state["checksideloader" +i] == null){
          urlencoded.append("checksideloader"+i, 0);
        }else{
          if(this.state["checksideloader" +i] == true){
            urlencoded.append("checksideloader"+i, 1);
          }else{
            urlencoded.append("checksideloader"+i, 0);
          }
          
        }
        
       
        //urlencoded.append("requestdate"+i,requestdate);
  
        
      }

      //for print ROT
      this.setState((prevState) => {
        const containerlist = [];
    
        for (let i = 0; i < numberrow; i++) {
            const containerValue = prevState["container" + i];
            if (containerValue !== null) {  // Only push if not null
                containerlist.push(containerValue);
            }
        }
    
        return { containerlist };
    }, () => console.log(this.state.containerlist));
      
      urlencoded.append("vesselname", this.state.vesselname);
      urlencoded.append("vesselvoyage", this.state.vesselvoyage);
      urlencoded.append("commodity", this.state.commodity);
      urlencoded.append("forwardingagentdropdowntext", this.state.forwardingagentdropdowntext);
  
      console.log("Remark: " + this.state.remark)
      urlencoded.append("createdby",this.state.Createdbyaccount)

      if(this.state.remark == null || this.state.remark == ''){
        urlencoded.append("remark", '-');
      }else{
        urlencoded.append("remark", this.state.remark);
      }

      
      
  
      //file upload2
  
      let link = this.state.linkurl +'mode=admin-addnewrotapi-version2'
      fetch(link, {
        method: 'POST', 
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body:urlencoded
      })
      .then(response => response.json())
      .then(json => {
        console.log(json.success)
        if(json.success == 1){
          
              console.log(json)
              //success
              console.log("rotnumberfeedback: " + json.rotnumber)

              this.setState((prevState) => {
                const consignmentlist = [];
                
                for (let g = 0; g < numberrow; g++) {
                    consignmentlist.push(json.rotnumber + "-" + (g + 1));
                }
            
                return { consignmentlist };
            }, () => console.log(this.state.consignmentlist)); 


              this.setState({rotnumbercomeback:json.rotnumber})
              this.setState({aftersubmitstatus:1})
              this.setState({numberrowaftersubmit:numberrow})
              //this.uploadfilefrom1to10(json.rotnumber)
              //console.log(111json.user)
              //  var lengthemployee = Object.keys(json.user).length;
              //  for(let i=0;i<lengthemployee;i++){
  
              //   //status need do
              //    this.setState({rotpendinglist:[...this.state.rotpendinglist,
              //     { no:i+1,
              //       rotno:json.user[i].Rotnumber,
              //       shippername:json.user[i].Shipper,
              //       totalvolume:json.user[i].totalvolume,
              //       bookingno:json.user[i].Bookingno,
              //       status:1,
              //       remark:json.user[i].Remark,
              //     },            
              //   ]})
                
                
               
              //    console.log(this.state.rotpendinglist)
               
  
               
              //   }
                this.setState({loading:false})  
              }        
                 
           else{
             //fail submit
            this.setState({aftersubmitstatus:2})
            this.setState({loading:false}) 
          console.log(json)
        }
      
       })
    
  


   
  }
}else{

    errorornot=true;
}

  }

  getdetailquotationtableapi =()=>{
    this.setState({loading:true})
    //console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl +'mode=admin-getquotationlistfinal'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
     // console.log(json.success)
      if(json.success == 1){
        
           // console.log(json)
            this.setState({quotationtabledatafinal:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

          
               this.setState({quotationtabledatafinal:[...this.state.quotationtabledatafinal,
                { no:i+1,
                  shippername:json.user[i].Shipper,
                  area:json.user[i].Area,
                  address:json.user[i].Address,
                  title:json.user[i].Shipper,
                  picvalue:json.user[i].Personincharge,
                  contactvalue:json.user[i].Contact,
                },            
              ]})
              
              
              this.setState({aftersubmitstatus:0})
               //console.log(this.state.quotationtabledatafinal)
             

             
              }
              //this.setState({loading:false})  
              
            }        
               
         else{
          this.setState({loading:false}) 
          this.setState({aftersubmitstatus:0})
        console.log(json)
      }
    
     })

    
       

   
  }

  getoperatorlistapi =() =>{
    this.setState({loading:true})
   // console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl +'mode=admin-getoperatorlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
     // console.log(json.success)
      if(json.success == 1){
        
          // console.log(json)
            this.setState({operatorlistdata:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({operatorlistdata:[...this.state.operatorlistdata,
                { no:i+1,
                  operator:json.user[i].Operator,
                  title:json.user[i].Operator,
                  value:json.user[i].Operator,
                },            
              ]})
             //  console.log(this.state.operatorlistdata)
              }
             // this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  
  }

  getrecentautocomplete = () =>{
    this.setState({loading:true})
   // console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("createdbyref", this.state.Createdbyref);

    let link = this.state.linkurl +'mode=admin-getrecentautocompletelistrot'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
     // console.log(json.success)
      if(json.success == 1){
        
           // console.log(json)
            this.setState({vesselnamerecentlist:[]});   
            this.setState({vesselvoyagerecentlist:[]});   
            this.setState({commodityrecentlist:[]});   
            this.setState({bookingrecentlist:[]});   
            this.setState({polrecentlist:[]});
            this.setState({podrecentlist:[]});

             var lengthvesselname = Object.keys(json.vesselname).length;
             for(let i=0;i<lengthvesselname;i++){

               this.setState({vesselnamerecentlist:[...this.state.vesselnamerecentlist,
                { no:i+1,
                  title:json.vesselname[i].Vesselname,
                },            
              ]})
            }

            var lengthvesselvoyage = Object.keys(json.vesselvoyage).length;
            for(let i=0;i<lengthvesselvoyage;i++){
              this.setState({vesselvoyagerecentlist:[...this.state.vesselvoyagerecentlist,
                { no:i+1,
                  title:json.vesselvoyage[i].Vesselvoyage,
                },            
              ]})
            }

            var lengthcommodity = Object.keys(json.commodity).length;
            for(let i=0;i<lengthcommodity;i++){
              this.setState({commodityrecentlist:[...this.state.commodityrecentlist,
                { no:i+1,
                  title:json.commodity[i].Commodity,
                },            
              ]})
            }

            var lengthpollist = Object.keys(json.pollist).length;
            for(let i=0;i<lengthpollist;i++){
              this.setState({polrecentlist:[...this.state.polrecentlist,
                { no:i+1,
                  title:json.pollist[i].POL,
                },            
              ]})
            }

            var lengthpodlist = Object.keys(json.podlist).length;
            for(let i=0;i<lengthpodlist;i++){
              this.setState({podrecentlist:[...this.state.podrecentlist,
                { no:i+1,
                  title:json.podlist[i].POD,
                },            
              ]})
            }

            //   console.log(this.state.yardlist)
              

            
              //this.setState({loading:false})   
              
              
            }    
              
               


         else{
          this.setState({vesselnamerecentlist:[...this.state.vesselnamerecentlist,
            { no:1,
              title:"-",
            },            
          ]})
          this.setState({vesselvoyagerecentlist:[...this.state.vesselvoyagerecentlist,
            { no:1,
              title:"-",
            },            
          ]})
          this.setState({commodityrecentlist:[...this.state.commodityrecentlist,
            { no:1,
              title:"-",
            },            
          ]})
          this.setState({bookingrecentlist:[...this.state.bookingrecentlist,
            { no:1,
              title:"-",
            },            
          ]})
          this.setState({polrecentlist:[...this.state.polrecentlist,
            { no:1,
              title:"-",
            },            
          ]})
          this.setState({podrecentlist:[...this.state.podrecentlist,
            { no:1,
              title:"-",
            },            
          ]})
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  getbookingnoprevious = () => {
    this.setState({loading:true})
   // console.log("booking console")
    var urlencoded = new URLSearchParams();

    //till here gonna go api
    let link = this.state.linkurl +'mode=admin-getbookingnoprevious'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      if(json.success == 1){
        
            //console.log("bookingjson:" + json)  
            this.setState({bookingalllist:[]});   
           
             var lengthbookinglist = Object.keys(json.bookinglist).length;
             for(let i=0;i<lengthbookinglist;i++){
              //console.log("json.bookinglist[i].Bookingno:" + json.bookinglist[i].Bookingno)    
              
               this.setState({bookingalllist:[...this.state.bookingalllist,
                { no:i+1,
                  title:json.bookinglist[i].Bookingno,
                },            
              ]})
            }     
            
            
             //console.log("bookingallist:" + this.state.bookingalllist);
              
            
            
            
            // var lengthbookinglistnumber = Object.keys(this.state.bookingalllist).length;

            // console.log("lengthbooking: " + lengthbookinglistnumber)

            //  for(let x=0;x<lengthbookinglistnumber;x++){

            //   console.log(this.state.bookingallist[x].title);
            // }   
            
            
            
            
            
            
            
            
            
            this.setState({loading:false})                
              
            }    
              
               



         else{
          this.setState({bookingalllist:[...this.state.bookingalllist,
            { no:1,
              title:"-",
            },            
          ]})
         
          this.setState({loading:false}) 
        
      }
    
     })
  }

  getpickupyardreturnyardlist =()=>{
    this.setState({loading:true})
    //console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl +'mode=admin-getpickupyardreturnyardlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
     // console.log(json)
      if(json.success == 1){
        
            //console.log(json)
            this.setState({yardlist:[]});   
            this.setState({returnyardlist:[]});
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({yardlist:[...this.state.yardlist,
                { no:i+1,
                  area:json.user[i].Area,
                  title:json.user[i].Area,
                },            
              ]})

                this.setState({returnyardlist:[...this.state.returnyardlist,
                  { no:i+1,
                    area:json.user[i].Area,
                    title:json.user[i].Area,
                  },            
                ]})
               //console.log(this.state.yardlist)
              }
             // this.setState({loading:false})  
              // this.setState({returnyardlist:[...this.state.returnyardlist,
              //   { no:lengthemployee+1,
              //     area:"**TBC (TO BE CONFIRM)",
              //     title:"**TBC (TO BE CONFIRM)",
              //   },            
              // ]})
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  getcontainertypelist =(pguorptp,shippername,shipmenttype)=>{
    this.setState({loading:true})
    this.setState({typelist:[]});  
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    urlencoded.append("shipper", shippername);

    // console.log("PGUPTPhere: " + pguorptp)
    // console.log("shipmenttype: " + shipmenttype)
    if(shipmenttype == 'Export'){
      if(pguorptp == 'NORTH PORT-CT1' || pguorptp == 'NORTH PORT-CT2' ){
       // console.log("PGUPTPhere: " + pguorptp)
        urlencoded.append("Pguorptp", 'NPort');
      }else if(pguorptp == 'WEST PORT'){
        urlencoded.append("Pguorptp", 'WPort');
      }
    }else if(shipmenttype == 'Import'){
      if(pguorptp == 'NORTH PORT-CT1' || pguorptp == 'NORTH PORT-CT2' ){
        urlencoded.append("Pguorptp", 'NPort');
      }else if(pguorptp == 'WEST PORT'){
        urlencoded.append("Pguorptp", 'WPort');
      }
    }
    // console.log(this.state.Billtoparty)
    // console.log(shippername)
    // console.log(pguorptp)

    let link = this.state.linkurl +'mode=admin-getcontainertypelist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json)
      if(json.success == 1){
        
          //  console.log(json)
            this.setState({typelist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({typelist:[...this.state.typelist,
                { no:i+1,
                  size:json.user[i].Size,
                  type:json.user[i].Type,
                  title:json.user[i].Size +" "+json.user[i].Type,
                },            
              ]})
              this.setState({typelistfirstone:[...this.state.typelistfirstone,
                { no:i+1,
                  size:json.user[i].Size,
                  type:json.user[i].Type,
                  title:json.user[i].Size +" "+json.user[i].Type,
                },            
              ]})
               console.log(this.state.typelist)
              }
              this.setState({typelist:[...this.state.typelist,
                { no:0,
                  size:"None",
                  type:"None",
                  title:"None",
                },            
              ]}) 
              this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  getforwardingagentlist = ()=>{
    this.setState({loading:true})
    console.log(this.state.Billtoparty)
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtoparty", this.state.Billtoparty);
    let link = this.state.linkurl +'mode=admin-getforwardingagentlist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
     // console.log(json.success)
      if(json.success == 1){
        
           // console.log(json)
            this.setState({forwardingagentlist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){

               this.setState({forwardingagentlist:[...this.state.forwardingagentlist,
                { no:i+1,
                  forwardingagent:json.user[i].companyname,
                  title:json.user[i].companyname,
                },            
              ]})
               console.log(this.state.forwardingagentlist)
              }
              //this.setState({loading:false})  
            }        
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }

  addvolumebuttonclicked = () =>{
    console.log("operatorlist: "+this.state.operatoreadropdowntext)
    if(this.state.volumeshowrow >= 8){
      
    }else{
      this.setState({volumeshowrow:this.state.volumeshowrow+1})
    }
    
    var numbershowtemp = this.state.volumeshowrow+1
    console.log(numbershowtemp)
        if(numbershowtemp == 0){
          this.setState({
            rowvolume1:false,
            rowvolume2:false,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:false,
          })
        } else if(numbershowtemp == 1){
          this.setState({
            rowvolume1:true,
            rowvolume2:false,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        } else if(numbershowtemp == 2){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        } else if(numbershowtemp == 3){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        } else if(numbershowtemp == 4){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        }else if(numbershowtemp == 5){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        }else if(numbershowtemp ==6){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        }else if(numbershowtemp == 7){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:true,
            rowvolume8:false,
            minusbuttonshow:true,
          })
        }else if(numbershowtemp == 8){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:true,
            rowvolume8:true,
            minusbuttonshow:true,
          })
        }
  }
  
  minusvolumebuttonclicked = () =>{
    if(this.state.volumeshowrow <= 0){
    }else{
      this.setState({volumeshowrow:this.state.volumeshowrow-1})
    }
  
    var numbershowtemp = this.state.volumeshowrow-1
    console.log(numbershowtemp)
        if(numbershowtemp == 0){
          this.setState({
            rowvolume1:false,
            rowvolume2:false,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:false,
            volumenumberinput2:0,
          })
        } else if(numbershowtemp == 1){
          this.setState({
            rowvolume1:true,
            rowvolume2:false,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput3:0,
          })
        } else if(numbershowtemp == 2){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:false,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput4:0,
          })
        } else if(numbershowtemp == 3){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:false,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput5:0,
          })
        } else if(numbershowtemp == 4){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:false,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput6:0,
          })
        }else if(numbershowtemp == 5){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:false,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput7:0,
          })
        }else if(numbershowtemp ==6){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:false,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput8:0,
          })
        }else if(numbershowtemp == 7){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:true,
            rowvolume8:false,
            minusbuttonshow:true,
            volumenumberinput9:0,
          })
        }else if(numbershowtemp == 8){
          this.setState({
            rowvolume1:true,
            rowvolume2:true,
            rowvolume3:true,
            rowvolume4:true,
            rowvolume5:true,
            rowvolume6:true,
            rowvolume7:true,
            rowvolume8:true,
            minusbuttonshow:true,
            
          })
        }
  }

  functionselectshipmenttype =(shippername2)=>{
       
    var data = this.state.quotationtabledatafinal.filter(item => item.shippername === shippername2)
   
    this.setState({type1dropdowntext:''})
    this.setState({type1dropdowntext1:''})
    this.setState({type1dropdowntext2:''})
    this.setState({type1dropdowntext3:''})
    this.setState({type1dropdowntext4:''})
    this.setState({type1dropdowntext5:''})
    this.setState({type1dropdowntext6:''})
    this.setState({type1dropdowntext7:''})
    this.setState({type1dropdowntext8:''})
    this.setState({type1dropdowntext9:''})
    
    if(data[0] != null){
      
      this.setState({
        areadropdowntext:shippername2,
        deliveryaddress:data[0].address,
        areatextbox:data[0].area,
        picvalue:data[0].picvalue,
        contactvalue:data[0].contactvalue,
      })

      if(data[0].picvalue == ""){
        this.setState({picvalue:"-"});
      }
      if(data[0].contactvalue ==""){
        this.setState({contactvalue:"-"});
      }
    }

    if(shippername2 ==""){
      this.setState({
        areadropdowntext:"",
        deliveryaddress:"",
        areatextbox:"",
        picvalue:"",
        contactvalue:"",
        
      })
    }
   
    console.log(shippername2)
    console.log(this.state.poltext)
    console.log(this.state.podtext)

    if(this.state.shipmenttypeadropdowntext =='Export'){
     this.getcontainertypelist(this.state.poltext,shippername2,this.state.shipmenttypeadropdowntext)
    } else if(this.state.shipmenttypeadropdowntext =='Import'){
     this.getcontainertypelist(this.state.podtext,shippername2,this.state.shipmenttypeadropdowntext)
    } else{

    }
  }
//   rendershipperdropdownmenu() {
   

//     return this.state.quotationtabledatafinal.map((data, index) => {
//        const {address,area,shippername} = data //destructuring
      
//        return (
//         <DropdownItem onClick={this.functionselectshipmenttype.bind(this,shippername,area,address)}>{shippername}</DropdownItem>
//        )
//     })
//  }
 renderoperatordropdownmenu() {
      

  return this.state.operatorlistdata.map((data, index) => {
     const {operator} = data //destructuring
     return (
      <DropdownItem onClick={() => this.setState({
        operatoreadropdowntext:operator,
      })}>{operator}</DropdownItem>
     )
  })
}
//  renderconsignmentnorow(totalrownumber) {

    
//      return (
//       <Row>

//       <Col md="3">
        
//           <FormGroup>
//           <Input
//               id="Consignmentnoinput2"
//               placeholder="Consignment No"
//               type="text"
//             />
//           </FormGroup>
//         </Col>
//         <Col md="3">

//           <FormGroup>
//           <Input
//               id="Containernoinput2"
//               placeholder="Container No"
//               type="text"
//             />
//           </FormGroup>
//         </Col>
//         <Col md="3">
//           <FormGroup>
//           <Input
//               id="Sealnoinput2"
//               placeholder="Seal No"
//               type="text"
//             />
//           </FormGroup>
//           </Col>
//           <Col md="2">
//           <FormGroup>
//           <InputGroup className="input-group-alternative">
//                   <InputGroupAddon addonType="prepend">
//                     <InputGroupText>
//                       <i className="ni ni-calendar-grid-58" />
//                     </InputGroupText>
//                   </InputGroupAddon>
//                   <ReactDatetime
//                     inputProps={{
//                       placeholder: "Request Date"
//                     }}
//                     timeFormat={false}
//                   />
//                 </InputGroup>
//           </FormGroup>
//           </Col>
//           <Col md="1">
//           <FormGroup>
//               <div className="custom-control custom-checkbox mb-3">
//               <input
//                 className="custom-control-input"
//                 id="customCheck1"
//                 type="checkbox"
//                 onChange={(e)=>console.log("checkboxchecked:" + e.target.value)}
//               />
//               <label className="custom-control-label" htmlFor="customCheck1">
//                 TBE
//               </label>
//             </div>
//           </FormGroup>
//           </Col>
//       </Row>
//      )
  
// }
renderforwardingagentrow() {
      

  return this.state.forwardingagentlist.map((data, index) => {
     const {forwardingagent} = data //destructuring
     return (

      <DropdownItem onClick={() => this.setState({
        forwardingagentdropdowntext:forwardingagent,
      })}>{forwardingagent}</DropdownItem>
     )
  })
}

renderaddinvolumerow(){
  // console.log("checktbevalue0: " + this.state.checktbe0)
  // console.log("checktbevalue1: " + this.state.checktbe1)
  // console.log("checktbevalue2: " + this.state.checktbe2)
  // console.log("checktbevalue3: " + this.state.checktbe3)
  // console.log("checktbevalue4: " + this.state.checktbe4)
  // console.log("checktbevalue5: " + this.state.checktbe5)
  // console.log("reque122stdate11: " + this.state.requestdate0)
  // console.log("requestdatedisable: " + this.state.requestdatedisable0)
  let consignmentnorow=[]
  let numberrow = Number(this.state.volumenumberinput1)+Number(this.state.volumenumberinput2) + 
                  Number(this.state.volumenumberinput3)+Number(this.state.volumenumberinput4) +
                  Number(this.state.volumenumberinput5)+Number(this.state.volumenumberinput6) +
                  Number(this.state.volumenumberinput7)+Number(this.state.volumenumberinput8) +
                  Number(this.state.volumenumberinput9)+Number(this.state.volumenumberinput) 

                 


  for(let i=0;i<numberrow;i++){
    var numbershow = i+1
    
    consignmentnorow.push(
      <Row>

      <Col md="2">
        
          <FormGroup>
          <Input
              id="Consignmentnoinput2"
              placeholder="Consignment No"
              type="text"
              disabled
              value= {"No. " + numbershow}
              
            />
          </FormGroup>
        </Col>
        <Col md="3">

          <FormGroup>
          <Input
              id="Containernoinput2"
              placeholder="Container No"
              className="textcolorblack"
              value={this.state["container" + i]}
              onChange = {(event) => this.setState({ ["container" + i]: event.target.value })}
              type="text"
            />
          </FormGroup>
        </Col>
        <Col md="3">
          <FormGroup>
          <Input
              id="Sealnoinput2"
              placeholder="Seal No"
              className="textcolorblack"
              type="text"
              value={this.state["Seal" + i]}
              onChange = {(e) => this.setState({ ["Seal" + i]: e.target.value })}
            />
          </FormGroup>
          </Col>
          <Col md="2">
          <FormGroup>
            {this.state["requestdatedisable"+i]==true?
                <Input
                id="requestdatetbe"
                placeholder="TBC"
                type="text"
                disabled={true}
                value={"TBC"}
              />
            :
                  <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-calendar-grid-58" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <ReactDatetime
                    inputProps={{
                      placeholder: "Request Date"
                    }}
                    closeOnSelect={true}
                    dateFormat="DD/MM/YYYY"
                    timeFormat={false}
                    value={this.state["requestdate" + i]}
                    onChange = {(e) => this.setState({ ["requestdate" + i]: e })}
                    
                  />
                </InputGroup> 
            }
        
     
          </FormGroup>
          </Col>
          <Col md="1">
          <FormGroup>
            
            <div className="custom-control custom-control-alternative custom-checkbox mb-3">
              <input
                className="custom-control-input"
                id={"customCheck"+i}
                type="checkbox"
                checked={this.state["checktbe" + i]}
                onChange={(e)=>this.checktbefunction(i)}
              />
              <label className="custom-control-label" htmlFor={"customCheck"+i}>
                TBC
              </label>
            </div>
          </FormGroup>
          </Col>
          <Col md="1">
          <FormGroup>
            
            <div className="custom-control custom-control-alternative custom-checkbox mb-3">
              <input
                className="custom-control-input"
                id={"customChecksideloader"+i}
                type="checkbox"
                checked={this.state["checksideloader" + i]}
                onChange={(e)=>this.checksidefunction(i)}
              />
              <label className="custom-control-label" htmlFor={"customChecksideloader"+i}>
                SL
              </label>             
            </div>
          </FormGroup>
          </Col>
         
      </Row>
    )  
  }
  
    return consignmentnorow
    
}
  
renderyardlistdropdownmenu() {
  return this.state.yardlist.map((data, index) => {
     const {area} = data //destructuring
     //console.log(area)
     return (
      <DropdownItem onClick={() => this.setState({
        pickupdropdowntext:area,
      })}>{area}</DropdownItem>
     )
  })
}

// rendertypelistdropdownmenu() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }

// rendertypelistdropdownmenu1() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext1:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }
// rendertypelistdropdownmenu2() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext2:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }
// rendertypelistdropdownmenu3() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext3:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }
// rendertypelistdropdownmenu4() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext4:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }
// rendertypelistdropdownmenu5() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext5:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }
// rendertypelistdropdownmenu6() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext6:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }
// rendertypelistdropdownmenu7() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext7:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }
// rendertypelistdropdownmenu8() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext8:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }
// rendertypelistdropdownmenu9() {
      
//   return this.state.typelist.map((data, index) => {
//      const {size,type} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         type1dropdowntext9:(size+' '+type),
//       })}>{size+' '+type}</DropdownItem>
//      )
//   })
// }

// renderreturnyardlistdropdownmenu() {
//   return this.state.yardlist.map((data, index) => {
//      const {area} = data //destructuring
//      return (
//       <DropdownItem onClick={() => this.setState({
//         returnyarddropdowntext:area,
//       })}>{area}</DropdownItem>
//      )
//   })
// }

callgettypelistfunctionpol = (pguorptp) =>{
  this.setState({poltext:pguorptp})
  this.getcontainertypelist(pguorptp,this.state.areadropdowntext,this.state.shipmenttypeadropdowntext);
}

callgettypelistfunctionshipmenttype= (value) =>{
  this.setState({shipmenttypeadropdowntext:value})
  //this.setState({poltext:''})
  //this.setState({podtext:''})
  console.log("pod: " + this.state.podtext)
  console.log("pol: " + this.state.poltext)
  this.setState({typelist:[]})

  if(value =='Export'){
    if(this.state.poltext !=''){
      if(this.state.areadropdowntext != ''){
        console.log("pol2: " + this.state.poltext)
        this.getcontainertypelist(this.state.poltext,this.state.areadropdowntext,value)
      }
     
    }   
   } else if(value =='Import'){
     if(this.state.podtext != ''){
      if(this.state.areadropdowntext != ''){
        this.getcontainertypelist(this.state.podtext,this.state.areadropdowntext,value )
      }
     } 
   } else{
    console.log("no able to get container type list")
   }
  // if(value =='Export'){
  //   this.getcontainertypelist(this.state.poltext,this.state.areadropdowntext)
  //  } else if(value =='Import'){
  //   this.getcontainertypelist(this.state.podtext,this.state.areadropdowntext)
  //  } else{

  //  }
}

callgettypelistfunctionpod = (pguorptp) =>{
  this.setState({podtext:pguorptp})
  this.getcontainertypelist(pguorptp,this.state.areadropdowntext,this.state.shipmenttypeadropdowntext);
}


checktbefunction = (number) =>{
  
  this.setState({["requestdate" + number]:946684800})
  this.setState({ ["checktbe" + number]: !this.state["checktbe" + number]})
  this.setState({["requestdatedisable" + number]:!this.state["checktbe" + number]})
 
  console.log("checktbecheck: " + this.state["checktbe" + number])
  if(this.state["checktbe" + number] == false){
    this.setState({["requestdate" + number]:946684800})
    //console.log("emptycheck")
  }else if(this.state["checktbe" + number]){
   this.setState({["requestdate" + number]:new Date().getTime()})
    //console.log("bplacecheck1")
  }else{
    this.setState({["requestdate" + number]:946684800})
  }
  
  // console.log("requestdatedisable: "+this.state["requestdatedisable" + number])
  // console.log("requestdate: "+this.state["requestdate" + number])
  // console.log("checktbe: "+this.state["checktbe" + number])
}


checksidefunction = (number) =>{
  

  this.setState({ ["checksideloader" + number]: !this.state["checksideloader" + number]}) 
  console.log("checksideloader: " + this.state["checksideloader" + number])

}




sameallthedate = () =>{
  console.log("requestdate0:" + this.state.requestdate0)
  this.setState({ samedate: !this.state.samedate})
  let numberrow = Number(this.state.volumenumberinput1)+Number(this.state.volumenumberinput2) + 
  Number(this.state.volumenumberinput3)+Number(this.state.volumenumberinput4) +
  Number(this.state.volumenumberinput5)+Number(this.state.volumenumberinput6) +
  Number(this.state.volumenumberinput7)+Number(this.state.volumenumberinput8) +
  Number(this.state.volumenumberinput9)+Number(this.state.volumenumberinput) 

  if(this.state.checktbe0 == true){
    //disable all below
    for(let i=0;i<numberrow;i++){
      this.setState({ ["requestdatedisable" + i]: true })
      this.setState({["requestdate" + i]:946684800})
      this.setState({ ["checktbe" + i]: true})
    }
  }else{
    for(let i=0;i<numberrow;i++){
      this.setState({ ["requestdate" + i]: this.state.requestdate0 })
    }
  }
 
}

buttonaddattachmentclick = () =>{
  
  this.setState({attachmentquantity:this.state.attachmentquantity + 1}) 
  if(this.state.attachmentquantity == 1){
    this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:false})
    this.setState({fileuploadnumber4:false})
    this.setState({fileuploadnumber5:false})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 2){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:false})
    this.setState({fileuploadnumber5:false})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 3){
      this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:true})
      this.setState({fileuploadnumber4:true})
      this.setState({fileuploadnumber5:false})
      this.setState({fileuploadnumber6:false})
      this.setState({fileuploadnumber7:false})
      this.setState({fileuploadnumber8:false})
      this.setState({fileuploadnumber9:false})
      this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 4){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 5){
      this.setState({fileuploadnumber2:true})
      this.setState({fileuploadnumber3:true})
      this.setState({fileuploadnumber4:true})
      this.setState({fileuploadnumber5:true})
      this.setState({fileuploadnumber6:true})
      this.setState({fileuploadnumber7:false})
      this.setState({fileuploadnumber8:false})
      this.setState({fileuploadnumber9:false})
      this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 6){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 7){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:true})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 8){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:true})
    this.setState({fileuploadnumber9:true})
    this.setState({fileuploadnumber10:false})
    }else if(this.state.attachmentquantity == 9){
      this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:true})
    this.setState({fileuploadnumber6:true})
    this.setState({fileuploadnumber7:true})
    this.setState({fileuploadnumber8:true})
    this.setState({fileuploadnumber9:true})
    this.setState({fileuploadnumber10:true})
    }else if(this.state.attachmentquantity == 10){
      this.setState({attachmentquantity:10})
    }

    console.log("attachmentquantity: " + this.state.attachmentquantity)
}

//this.uploadfilefrom1to10()
buttoncancelattachmentclick = () =>{
  this.setState({attachmentquantity:this.state.attachmentquantity - 1}) 
  
  if(this.state.attachmentquantity == 1){  
  this.setState({attachmentquantity:1})
  }else if(this.state.attachmentquantity == 2){
    this.setState({fileuploadnumber2:false})
  this.setState({fileuploadnumber3:false})
  this.setState({fileuploadnumber4:false})
  this.setState({fileuploadnumber5:false})
  this.setState({fileuploadnumber6:false})
  this.setState({fileuploadnumber7:false})
  this.setState({fileuploadnumber8:false})
  this.setState({fileuploadnumber9:false})
  this.setState({fileuploadnumber10:false})
  }else if(this.state.attachmentquantity == 3){
    this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:false})
    this.setState({fileuploadnumber4:false})
    this.setState({fileuploadnumber5:false})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
  }else if(this.state.attachmentquantity == 4){
    this.setState({fileuploadnumber2:true})
  this.setState({fileuploadnumber3:true})
  this.setState({fileuploadnumber4:false})
  this.setState({fileuploadnumber5:false})
  this.setState({fileuploadnumber6:false})
  this.setState({fileuploadnumber7:false})
  this.setState({fileuploadnumber8:false})
  this.setState({fileuploadnumber9:false})
  this.setState({fileuploadnumber10:false})
  }else if(this.state.attachmentquantity == 5){
    this.setState({fileuploadnumber2:true})
    this.setState({fileuploadnumber3:true})
    this.setState({fileuploadnumber4:true})
    this.setState({fileuploadnumber5:false})
    this.setState({fileuploadnumber6:false})
    this.setState({fileuploadnumber7:false})
    this.setState({fileuploadnumber8:false})
    this.setState({fileuploadnumber9:false})
    this.setState({fileuploadnumber10:false})
  }else if(this.state.attachmentquantity == 6){
    this.setState({fileuploadnumber2:true})
  this.setState({fileuploadnumber3:true})
  this.setState({fileuploadnumber4:true})
  this.setState({fileuploadnumber5:true})
  this.setState({fileuploadnumber6:false})
  this.setState({fileuploadnumber7:false})
  this.setState({fileuploadnumber8:false})
  this.setState({fileuploadnumber9:false})
  this.setState({fileuploadnumber10:false})
  }else if(this.state.attachmentquantity == 7){
    this.setState({fileuploadnumber2:true})
  this.setState({fileuploadnumber3:true})
  this.setState({fileuploadnumber4:true})
  this.setState({fileuploadnumber5:true})
  this.setState({fileuploadnumber6:true})
  this.setState({fileuploadnumber7:false})
  this.setState({fileuploadnumber8:false})
  this.setState({fileuploadnumber9:false})
  this.setState({fileuploadnumber10:false})
  }else if(this.state.attachmentquantity == 8){
    this.setState({fileuploadnumber2:true})
  this.setState({fileuploadnumber3:true})
  this.setState({fileuploadnumber4:true})
  this.setState({fileuploadnumber5:true})
  this.setState({fileuploadnumber6:true})
  this.setState({fileuploadnumber7:true})
  this.setState({fileuploadnumber8:false})
  this.setState({fileuploadnumber9:false})
  this.setState({fileuploadnumber10:false})
  }else if(this.state.attachmentquantity == 9){
    this.setState({fileuploadnumber2:true})
  this.setState({fileuploadnumber3:true})
  this.setState({fileuploadnumber4:true})
  this.setState({fileuploadnumber5:true})
  this.setState({fileuploadnumber6:true})
  this.setState({fileuploadnumber7:true})
  this.setState({fileuploadnumber8:true})
  this.setState({fileuploadnumber9:false})
  this.setState({fileuploadnumber10:false})
  }else if(this.state.attachmentquantity == 10){
    this.setState({fileuploadnumber2:true})
  this.setState({fileuploadnumber3:true})
  this.setState({fileuploadnumber4:true})
  this.setState({fileuploadnumber5:true})
  this.setState({fileuploadnumber6:true})
  this.setState({fileuploadnumber7:true})
  this.setState({fileuploadnumber8:true})
  this.setState({fileuploadnumber9:true})
  this.setState({fileuploadnumber10:false})
  }
  console.log("attachmentquantity: " + this.state.attachmentquantity)
}

uploadfile1 = ({target:{files}}) =>{ 
  this.setState({percentupload1:0})
  this.setState({selectedFilename1:""}) 
  let attachmentnumber = 1
 
  const formData = new FormData();
 
    formData.append( 
      "myFile", 
      files[0] ,
    );

  
 
  formData.append( 
    "attachementsetID", 
    this.state.attachmentsetID,
  );
 
  formData.append( 
    "attachmentnumber", 
    attachmentnumber,
  );


  const config = {
    headers: {
        'content-type': 'multipart/form-data'
    }
}

const options = {
  onUploadProgress:(progressEvent) =>{
    const {loaded,total} = progressEvent;
    let percent = Math.floor(loaded*100/total)
    console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
    this.setState({percentupload1:percent})
  }
}

//upload go then feed back link, 
//the link update go current database

  axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
  .then(res => {
    //console.log(res);
    console.log(res.data);
    this.setState({selectedFilename1:res.data})
    console.log("selectedfilename1:" + this.state.selectedFilename1)
  }); 

}

uploadfile2 = ({target:{files}}) =>{ 
     this.setState({percentupload2:0})
     this.setState({selectedFilename2:""})   
  let attachmentnumber = 2
 
  const formData = new FormData();
 
    formData.append( 
      "myFile", 
      files[0] ,
    );

  
 
  formData.append( 
    "attachementsetID", 
    this.state.attachmentsetID,
  );
 
  formData.append( 
    "attachmentnumber", 
    attachmentnumber,
  );


  const config = {
    headers: {
        'content-type': 'multipart/form-data'
    }
}

const options = {
  onUploadProgress:(progressEvent) =>{
    const {loaded,total} = progressEvent;
    let percent = Math.floor(loaded*100/total)
    console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
    this.setState({percentupload2:percent})
  }
}

//upload go then feed back link, 
//the link update go current database

  axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
  .then(res => {
    //console.log(res);
    console.log(res.data);
    this.setState({selectedFilename2:res.data})    
    console.log("selectedfilename1:" + this.state.selectedFilename2)
  }); 

}

uploadfile3 = ({target:{files}}) =>{ 
  this.setState({percentupload3:0})
  this.setState({selectedFilename3:""})   
let attachmentnumber = 3

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload3:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename3:res.data})    
 console.log("selectedfilename1:" + this.state.selectedFilename3)
}); 

}

uploadfile4 = ({target:{files}}) =>{ 
  this.setState({percentupload4:0})
  this.setState({selectedFilename4:""})   
let attachmentnumber = 4

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload4:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename4:res.data})    
 //console.log("selectedfilename1:" + this.state.selectedFilename4)
}); 

}

uploadfile5 = ({target:{files}}) =>{ 
  this.setState({percentupload5:0})
  this.setState({selectedFilename5:""})   
let attachmentnumber = 5

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload5:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename5:res.data})    
 //console.log("selectedfilename1:" + this.state.selectedFilename4)
}); 

}

uploadfile6 = ({target:{files}}) =>{ 
  this.setState({percentupload6:0})
  this.setState({selectedFilename6:""})   
let attachmentnumber = 6

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload6:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename6:res.data})    
 //console.log("selectedfilename1:" + this.state.selectedFilename4)
}); 

}

uploadfile7 = ({target:{files}}) =>{ 
  this.setState({percentupload7:0})
  this.setState({selectedFilename7:""})   
let attachmentnumber = 7

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload7:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename7:res.data})    
 //console.log("selectedfilename1:" + this.state.selectedFilename4)
}); 

}

uploadfile8 = ({target:{files}}) =>{ 
  this.setState({percentupload8:0})
  this.setState({selectedFilename8:""})   
let attachmentnumber = 8

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload8:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename8:res.data})    
 //console.log("selectedfilename1:" + this.state.selectedFilename4)
}); 

}

uploadfile9 = ({target:{files}}) =>{ 
  this.setState({percentupload9:0})
  this.setState({selectedFilename9:""})   
let attachmentnumber = 9

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload9:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename9:res.data})    
 //console.log("selectedfilename1:" + this.state.selectedFilename4)
}); 

}

uploadfile10 = ({target:{files}}) =>{ 
  this.setState({percentupload10:0})
  this.setState({selectedFilename10:""})   
let attachmentnumber = 10

const formData = new FormData();

 formData.append( 
   "myFile", 
   files[0] ,
 );



formData.append( 
 "attachementsetID", 
 this.state.attachmentsetID,
);

formData.append( 
 "attachmentnumber", 
 attachmentnumber,
);


const config = {
 headers: {
     'content-type': 'multipart/form-data'
 }
}

const options = {
onUploadProgress:(progressEvent) =>{
 const {loaded,total} = progressEvent;
 let percent = Math.floor(loaded*100/total)
 console.log( loaded + 'kb of '+total+'kb | '+percent+'%')
 this.setState({percentupload10:percent})
}
}

//upload go then feed back link, 
//the link update go current database

axios.post(this.state.linkurl+"mode=admin-uploadfilewithloading_ver2", formData , options,config)
.then(res => {
 //console.log(res);
 console.log(res.data);
 this.setState({selectedFilename10:res.data})    
 //console.log("selectedfilename1:" + this.state.selectedFilename4)
}); 

}



uploadfilefrom1to10 = (rotnumber) =>{ 
     
    // Create an object of formData 
    const formData = new FormData(); 
    // formData.append( 
    //   'myFile1', 
    //   this.state.selectedFile1,
    // );
   
    console.log("formData: " + formData)
    console.log("selectedFile1: " + this.state.selectedFile1)
    // Update the formData object 
    if(this.state.attachmentquantity == 1){
      if(this.state.selectedFile1 !=null){
        formData.append( 
          "myFile1", 
          this.state.selectedFile1,
        );
      }
      
    }else if(this.state.attachmentquantity == 2){
      if(this.state.selectedFile1 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1,
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2,
      );  
      }
    }else if(this.state.attachmentquantity == 3){
      if(this.state.selectedFile3 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1,
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2, 
      ); 
      }
      if(this.state.selectedFile3!=null){
      formData.append( 
        "myFile3", 
        this.state.selectedFile3, 
      ); 
      }
    }else if(this.state.attachmentquantity == 4){
      if(this.state.selectedFile1 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1,  
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2, 
      ); 
      }
      if(this.state.selectedFile3 !=null){
      formData.append( 
        "myFile3", 
        this.state.selectedFile3, 
      ); 
      }
      if(this.state.selectedFile4 !=null){
      formData.append( 
        "myFile4", 
        this.state.selectedFile4, 
      ); 
      }
    }else if(this.state.attachmentquantity == 5){
      if(this.state.selectedFile1 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1, 
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2,
      ); 
      }
      if(this.state.selectedFile3 !=null){
      formData.append( 
        "myFile3", 
        this.state.selectedFile3,
      ); 
      }
      if(this.state.selectedFile4 !=null){
      formData.append( 
        "myFile4", 
        this.state.selectedFile4,
      ); 
      }
      if(this.state.selectedFile5 !=null){
      formData.append( 
        "myFile5", 
        this.state.selectedFile5,
      ); 
      }

    }else if(this.state.attachmentquantity == 6){
      if(this.state.selectedFile1 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1,  
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2, 
      ); 
      }
      if(this.state.selectedFile3 !=null){
      formData.append( 
        "myFile3", 
        this.state.selectedFile3, 
      ); 
      }
      if(this.state.selectedFile4 !=null){
      formData.append( 
        "myFile4", 
        this.state.selectedFile4, 
      ); 
      }
      if(this.state.selectedFile5 !=null){
      formData.append( 
        "myFile5", 
        this.state.selectedFile5,
      ); 
      }
      if(this.state.selectedFile6 !=null){        
      formData.append( 
        "myFile6", 
        this.state.selectedFile6, 
      ); 
      }
    }else if(this.state.attachmentquantity == 7){
      if(this.state.selectedFile1 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1, 
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2, 
      ); 
      }
      if(this.state.selectedFile3 !=null){
      formData.append( 
        "myFile3", 
        this.state.selectedFile3, 
      ); 
      }
      if(this.state.selectedFile4 !=null){
      formData.append( 
        "myFile4", 
        this.state.selectedFile4,
      ); 
      }
      if(this.state.selectedFile5 !=null){
      formData.append( 
        "myFile5", 
        this.state.selectedFile5, 
      ); 
      }
      if(this.state.selectedFile6 !=null){
      formData.append( 
        "myFile6", 
        this.state.selectedFile6,  
      ); 
      }
      if(this.state.selectedFile7 !=null){
      formData.append( 
        "myFile7", 
        this.state.selectedFile7, 
      );
      }
    }else if(this.state.attachmentquantity ==8){
      if(this.state.selectedFile1 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1,  
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2,  
      ); 
      }
      if(this.state.selectedFile3 !=null){
      formData.append( 
        "myFile3", 
        this.state.selectedFile3,  
      ); 
      }
      if(this.state.selectedFile4 !=null){
      formData.append( 
        "myFile4", 
        this.state.selectedFile4,  
      ); 
      }
      if(this.state.selectedFile5 !=null){
      formData.append( 
        "myFile5", 
        this.state.selectedFile5,  
      ); 
      }
      if(this.state.selectedFile6 !=null){
      formData.append( 
        "myFile6", 
        this.state.selectedFile6,  
      ); 
      }
      if(this.state.selectedFile7 !=null){
      formData.append( 
        "myFile7", 
        this.state.selectedFile7, 
      );
      }
      if(this.state.selectedFile8 !=null){
      formData.append( 
        "myFile8", 
        this.state.selectedFile8,  
      );
      }
    }else if(this.state.attachmentquantity == 9){
      if(this.state.selectedFile1 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1,  
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2,   
      ); 
      }
      if(this.state.selectedFile3 !=null){
      formData.append( 
        "myFile3", 
        this.state.selectedFile3,  
      ); 
      }
      if(this.state.selectedFile4 !=null){
      formData.append( 
        "myFile4", 
        this.state.selectedFile4,   
      ); 
      }
      if(this.state.selectedFile5 !=null){
      formData.append( 
        "myFile5", 
        this.state.selectedFile5,  
      ); 
      }
      if(this.state.selectedFile6 !=null){
      formData.append( 
        "myFile6", 
        this.state.selectedFile6,  
      ); 
      }
      if(this.state.selectedFile7 !=null){
      formData.append( 
        "myFile7", 
        this.state.selectedFile7,  
      );
      }
      if(this.state.selectedFile8 !=null){
      formData.append( 
        "myFile8", 
        this.state.selectedFile8, 
      );
      }
      if(this.state.selectedFile9 !=null){
      formData.append( 
        "myFile9", 
        this.state.selectedFile9,   
      );
      }
    }else if(this.state.attachmentquantity == 10){
      if(this.state.selectedFile1 !=null){
      formData.append( 
        "myFile1", 
        this.state.selectedFile1, 
      );
      }
      if(this.state.selectedFile2 !=null){
      formData.append( 
        "myFile2", 
        this.state.selectedFile2,  
      ); 
      }
      if(this.state.selectedFile3 !=null){
      formData.append( 
        "myFile3", 
        this.state.selectedFile3, 
      ); 
      }
      if(this.state.selectedFile4 !=null){
      formData.append( 
        "myFile4", 
        this.state.selectedFile4, 
      ); 
      }
      if(this.state.selectedFile5 !=null){
      formData.append( 
        "myFile5", 
        this.state.selectedFile5, 
      ); 
      }
      if(this.state.selectedFile6 !=null){
      formData.append( 
        "myFile6", 
        this.state.selectedFile6,  
      ); 
      }
      if(this.state.selectedFile7 !=null){
      formData.append( 
        "myFile7", 
        this.state.selectedFile7,  
      );
      }
      if(this.state.selectedFile8 !=null){
      formData.append( 
        "myFile8", 
        this.state.selectedFile8,  
      );
      }
      if(this.state.selectedFile9 !=null){
      formData.append( 
        "myFile9", 
        this.state.selectedFile9,   
      );
      }
      if(this.state.selectedFile10 !=null){
      formData.append( 
        "myFile10", 
        this.state.selectedFile10, 
      );
      }
    }
   
    formData.append( 
      "Rotnumber", 
      rotnumber
    );
   
    // Details of the uploaded file 
    console.log(this.state.selectedFile1); 
    console.log(this.state.selectedFile2); 
    console.log(this.state.selectedFile3); 
    console.log(this.state.selectedFile4); 
    console.log(this.state.selectedFile5); 
    console.log(this.state.selectedFile6); 
    console.log(this.state.selectedFile7); 
    console.log(this.state.selectedFile8); 
    console.log(this.state.selectedFile9);
    console.log(this.state.selectedFile10);  
    // Request made to the backend api 
    // Send formData object 
    const config = {
      headers: {
          'content-type': 'multipart/form-data'
      }
  }


    return post(this.state.linkurl+"mode=admin-uploadfileattachmentrot", formData,config )
    .then(res => {
      console.log(res);
      console.log(res.data);
    }); 
  
}

vsletachangedchoose = (e) =>{
  if(this.state.shipmenttypeadropdowntext =="Export"){
    this.setState({ Vsleta: e})
    var timestamp = e.unix()
    var daylate5r = timestamp - 86400 * 1 
    var closingdaymoment = Moment.unix(daylate5r)
    this.setState({closingday:closingdaymoment})
  }else{
    this.setState({ Vsleta: e})
    var timestamp = e.unix()
    var daylate5r = timestamp + 86400 * 5 
    var closingdaymoment = Moment.unix(daylate5r)
    this.setState({closingday:closingdaymoment})
  }
  
}

render() {
  
    return (
      <>
        <Header />
        {/* Page content */}
        <Container className=" mt--7" fluid>
          {/* Table */}
          <Row>
            <div className=" col">
              <Card className=" shadow">
              <CardHeader className="border-10-blue">
                  <Row className="align-items-center">
                    <div className="col">
                      <h3 className="mb-0">Create New ROT:</h3>
                    </div>
                    
                  </Row>
                </CardHeader>


              {
              /* Success Status*/
              this.state.aftersubmitstatus == 1
              ?             
              <CardBody>

              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
                <img 
                className="img-quotationlogodonenew"
                width="120"
                height="120"
                alt="..."
                src={require("./../../assets/img/brand/logomewah4.png")}
              />
              <p className="word-quotationaddnewfinish" >Thanks for choose our services,</p>
              <p className="word-quotationaddnewfinish">Your Rot Number is {this.state.rotnumbercomeback}.</p>
              <ReactToPrint
          trigger={() =>   <Button
            color="primary"
            data-dismiss="modal"
            type="button"
            to="/admin/orders"
            >
            Print Rot
            </Button>}
          content={() => this.componentRef}
        />
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              to="/admin/orders"
              tag={Link}
              >
              Go To Pending Approval
            </Button>
            <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              onClick={(e) => this.refreshallthing(this.state.numberrowaftersubmit)}
              >
              Create Another Rot
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Fail Status */
              this.state.aftersubmitstatus == 2
              ?
              <CardBody>
              <div className="modal-header">
          </div>
          <div className="modalforquotationaddnew">
          <Form>
            <Row>
            <Col md="12">
              <p className="h2">Submit Failed, Something Wrong, Please Try Again.</p>
              <Button
              color="primary"
              data-dismiss="modal"
              type="button"
              className="buttonmiddle"
              onClick={(e) => this.refreshallthing(this.state.numberrowaftersubmit)}
              >
              Retry
            </Button>
            </Col>
            
          </Row>
          </Form>
         </div>
           
          </CardBody>
              :
              /* Loading Status */
              this.state.aftersubmitstatus == 3
              ?
              <Row >
                <Col md="12" >
                  <img 
                  className="img-center"
                  width="120"
                  height="120"
                  alt="..."
                  src={require("./../../assets/gif/lorrygif3.gif")}
                />
                </Col>
            </Row>
              :
              <CardBody>
                <div className="modal-body p-10">
                <Form>
           

                  {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }
               

                  {/*Bill to Party*/}
                  <Row>
                    <Col md="12">
                      <p className="h5">Bill To Party:</p>
                      <FormGroup>
                        <Input
                          id="Billtopartyinput1"
                          placeholder="Bill To Party"
                          value={this.state.Billtoparty}
                          type="text"
                          disabled
                        />
                      </FormGroup>
                    </Col>
                    
                  </Row>
                  {/*Shipper*/}
                  <Row>
                  <Col md="12">
                      <p className="h5">Shipper/Consignee:</p>
                      <FormGroup >
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.quotationtabledatafinal}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.functionselectshipmenttype(value)}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.shippervalidation} helperText={this.state.shippervalidationmessage} />}
                        disableClearable={true}
                      />
                      {/* <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownarea} toggle={() => this.toggledropdownarea("isopendropdownarea")}>
                        <DropdownToggle className = "btn-block" block={true} caret>
                          {this.state.areadropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>
                        {this.rendershipperdropdownmenu()}
                        </DropdownMenu>
                      </Dropdown> */}
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  {/*Address*/}
                  <Row>
                  <Col md="12">
                      <p className="h5">Address:</p>
                      <FormGroup>
                      <Input
                          id="Addressinput1"
                          placeholder="Address"
                          type="text"
                          value={this.state.deliveryaddress}
                          disabled
                        />
                      </FormGroup>
                    </Col>
                  
                  </Row>
                  
                  {/*Area,PIC,Contact*/}
                  <Row>
                  <Col md="4">
                      <p className="h5">Area:</p>
                      <FormGroup>
                      <Input
                          id="areainput1"
                          placeholder="Area"
                          type="text"
                          value={this.state.areatextbox}
                          disabled
                        />
                      </FormGroup>
                      </Col>
                      <Col md="4">
                      <p className="h5">PIC:</p>
                      <FormGroup>
                      {/* {this.state.submitbuttonclick == 0?
                        
                        :
                        
                        } */}
                      <Input
                          id="Picinput1"
                          placeholder="PIC"
                          type="text"
                          disabled={true}
                          value={this.state.picvalue}
                          onChange={(e) => this.handleChange(e.target.value,"picvalue")}
                         
                        />
                         
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <p className="h5">Contact:</p>
                      <FormGroup>
                      <Input
                          id="Contactinput1"
                          placeholder="Contact"
                          type="text"
                          disabled={true}
                          onChange={(e) => this.handleChange(e.target.value,"contactvalue")}
                          value={this.state.contactvalue}
                         
                        />
                         
                      </FormGroup>
                    </Col>
                
                  </Row>
                  {/*ShipmentType,POL,POD*/}
                  <Row>
                  <Col md="4">
                      <p className="h5">Shipment Type:</p>
                      <FormGroup>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={[{title:"Export"},{title:"Import"}]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.callgettypelistfunctionshipmenttype(value)}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.shipmenttypevalidation} helperText={this.state.shipmenttypevalidationmessage} />}
                        disableClearable={true}
                     />
                      {/* <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownshipmenttype} toggle={() => this.toggledropdownarea("isopendropdownshipmenttype")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                          {this.state.shipmenttypeadropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>

                          <DropdownItem onClick={() => this.setState({shipmenttypeadropdowntext:'Export',
                        disableddropdownpol:false,
                        disableddropdownpod:false,})}>Export</DropdownItem>
                          <DropdownItem onClick={() => this.setState({shipmenttypeadropdowntext:'Import',
                        disableddropdownpol:false,
                        disableddropdownpod:false,})}>Import</DropdownItem>
                
                        </DropdownMenu>
                      </Dropdown> */}
                      </FormGroup>
                    </Col>

                    {this.state.shipmenttypeadropdowntext=='Import'?
                    
                      <Col md="4">
                      <p className="h5">POL:</p>
                      <FormGroup>
                      <Autocomplete
                          id="free-solo-demo"
                          freeSolo={true}
                          autoSelect
                          style={{ flex:1,display:"block",marginTop:-18 }}
                          options={this.state.polrecentlist.map((option) => option.title)}
                          onInputChange={(event,value) =>this.setState({poltext:event.target.value})}
                          renderInput={(params) => (
                            <TextField {...params} label="" margin="normal" variant="outlined" error={this.state.poltextvalidation} helperText={this.state.poltextvalidationmessage}  />
                          )}
                        />
                      {/* <Input
                          id="Pol1"
                          placeholder="POL"
                          type="text"
                          className="textcolorblack"
                          value={this.state.poltext}
                          onChange={(e) => this.setState({poltext:e.target.value})}    
                          invalid={this.state.poltextvalidation}                     
                        /> */}
                         {/* <FormFeedback invalid>Please fill in the detail</FormFeedback> */}
                      </FormGroup>
                    </Col>
         
                    :
                    <Col md="4">
                    <p className="h5">POL:</p>
                    <FormGroup>
                    <Autocomplete
                        id="combo-box-demo2"
                        options={[{title:"NORTH PORT-CT1"},{title:"NORTH PORT-CT2"},{title:"WEST PORT"} ]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.callgettypelistfunctionpol(value)}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.poltextvalidation} helperText={this.state.poltextvalidationmessage}  />}
                        disableClearable={true}
                     />
                    </FormGroup>
                  </Col>
                
                    }
              {this.state.shipmenttypeadropdowntext=='Import'?
                    
                  <Col md="4">
                     <p className="h5">POD:</p>
                     <FormGroup>
                     <Autocomplete
                        id="combo-box-demo2"
                        options={[{title:"NORTH PORT-CT1"},{title:"NORTH PORT-CT2"},{title:"WEST PORT"} ]}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.callgettypelistfunctionpod(value)}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.podtextvalidation} helperText={this.state.podtextvalidationmessage} />}
                        disableClearable={true}
                      />
                     </FormGroup>
                   </Col>
                 
                  :
                   <Col md="4">
                   <p className="h5">POD:</p>
                   <FormGroup>
                   <Autocomplete
                          id="Podinput1"
                          freeSolo={true}
                          autoSelect
                          style={{ flex:1,display:"block",marginTop:-18 }}
                          options={this.state.podrecentlist.map((option) => option.title)}
                          onInputChange={(event,value) =>this.setState({podtext:value})}
                          renderInput={(params) => (
                            <TextField {...params} label="" margin="normal" variant="outlined" error={this.state.podtextvalidation} helperText={this.state.podtextvalidationmessage} />
                          )}
                        />
                   {/* <Input
                       id="Podinput1"
                       placeholder="POD"
                       type="text"
                       className="textcolorblack"
                       value={this.state.podtext}                      
                       onChange={(e) => this.setState({podtext:e.target.value})}
                       invalid={this.state.podtextvalidation}
                     
                     /> */}
                       {/* <FormFeedback invalid>Please fill in the detail</FormFeedback> */}
                   </FormGroup>
                   </Col>
                  }
                  </Row>
                  {/*Bookingno,VslETA,ClosingDay*/}
                  <Row>
                  <Col md="4">
                      <p className="h5">Booking/BL No:</p>
                      <FormGroup>
                      <Input
                       id="bookingno"
                       placeholder="Booking No."
                       type="text"
                       className="textcolorblack"
                       value={this.state.bookingno}
                       onChange={(e) => this.setState({bookingno:e.target.value})}
                       invalid={this.state.bookingnovalidation}
                     />
                     <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <p className="h5">Vsl Eta:</p>
                      <FormGroup>
                      <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Vsl Eta",                  
                      }}    
                      
                    closeOnSelect={true}                  
                      value={this.state.Vsleta}
                      onChange={(e) => this.vsletachangedchoose(e)}
                      timeFormat={false}
                      dateFormat="DD/MM/YYYY"
                    />
                  </InputGroup>
                    <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <p className="h5">Closing/Last Day:</p>
                      <FormGroup>
                      <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-calendar-grid-58" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <ReactDatetime
                      inputProps={{
                        placeholder: "Closing / Last Day",                    
                      }}
                      
                    closeOnSelect={true}
                      timeFormat={false}
                      value={this.state.closingday}
                      dateFormat="DD/MM/YYYY"
                      onChange={e => this.setState({ closingday: e })}
                      
                    />
                  </InputGroup>
                        <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                      </Col>
                  </Row>
                  {/*Operator,Pickup*/}
                  {this.state.shipmenttypeadropdowntext=='Import'?
                  
                  
                 <Row>
                    
                 <Col md="4">
                     <p className="h5">Shipping Liner:</p>
                     <FormGroup>
                     <Autocomplete
                       id="combo-box-demo2"
                       options={this.state.operatorlistdata}
                       getOptionLabel={(option) => option.title}                       
                       style={{ flex:1,display:"block" }}
                       onInputChange={(event,value) =>this.setState({operatoreadropdowntext:value})}
                       renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.operationvalidation} helperText={this.state.operationvalidationmessage} />}
                       disableClearable={true}
                     />
                     </FormGroup>
                   </Col>
                   <Col md="4">
                     <p className="h5">Pick Up</p>
                     <FormGroup>
                     <Autocomplete
                       id="combo-box-demo2"
                       options={this.state.yardlist}
                       getOptionLabel={(option) => option.title}                       
                       style={{ flex:1,display:"block" }}
                       onInputChange={(event,value) =>this.setState({pickupdropdowntext:value})}
                       renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.pickupvalidation} helperText={this.state.pickupvalidationmessage} />}
                       disableClearable={true}
                     />
                     </FormGroup>
                   </Col>
                   <Col md="4">
                     <p className="h5">Return Yard</p>
                     <FormGroup>
                     <Autocomplete
                       id="combo-box-demo2"
                       options={this.state.returnyardlist}
                       getOptionLabel={(option) => option.title}                       
                       style={{ flex:1,display:"block" }}
                       onInputChange={(event,value) =>this.setState({returnyarddropdowntext:value})}
                       renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.returnvalidation} helperText={this.state.returnvalidationmessage} />}
                       disableClearable={true}
                    />
                     {/* <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownreturnyard} toggle={() => this.toggledropdownarea("isopendropdownreturnyard")}>
                       <DropdownToggle caret className = "btn-block" block={true}>
                         {this.state.returnyarddropdowntext}
                         </DropdownToggle>
                       <DropdownMenu>
                       {this.renderreturnyardlistdropdownmenu()}
                       </DropdownMenu>
                     </Dropdown> */}
                     </FormGroup>
                   </Col>

                 </Row>
                 
                    :
                    <Row>
                  <Col md="6">
                      <p className="h5">Shipping Liner:</p>
                      
                      <FormGroup>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.operatorlistdata}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({operatoreadropdowntext:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.operationvalidation} helperText={this.state.operationvalidationmessage} />}
                        disableClearable={true}
                      />
                      {/* <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownoperator} toggle={() => this.toggledropdownarea("isopendropdownoperator")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                          {this.state.operatoreadropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>
                        {this.renderoperatordropdownmenu()}
                        </DropdownMenu>
                      </Dropdown>
                    */}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <p className="h5">Pick Up</p>
                      <FormGroup>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.yardlist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({pickupdropdowntext:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.pickupvalidation} helperText={this.state.pickupvalidationmessage} />}
                        disableClearable={true}
                      />
                      {/* <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdownpickup} toggle={() => this.toggledropdownarea("isopendropdownpickup")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                          {this.state.pickupdropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>
                         {this.renderyardlistdropdownmenu()}
                        </DropdownMenu>
                      </Dropdown> */}
                      </FormGroup>
                    </Col>
                  </Row>
                 
                  }
 
                  {/*Volume*/}
                  <Row>
                    <Col md="12">
                    <p className="h5">Volume: (If the volume type not contain, please add at Quotation)</p>
                    </Col>
                  </Row>
                 

                  <Row>
                  <Col md="3">
                      <FormGroup>
                      <Input
                          name="volumenumberinput"
                          id="volumenumberinput"
                          placeholder="Volume"
                          type="number"
                          min="0"
                          className="textcolorblack"
                          value={this.state.volumenumberinput}
                          onChange={(e) => this.handleChange(e.target.value,"volumenumberinput")}
                          invalid={this.state.volumenumber1validation}
                          />
                        <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="9">
                      <FormGroup>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelistfirstone}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.type1validation} helperText={this.state.type1validationmessage}/>}
                        disableClearable={true}
                        noOptionsText={"Please complete above fill first/No exist in quotation"}
                     />
                      {/* <Dropdown size="lg" className = "btn-block" block={true}  isOpen={this.state.isopendropdowntype} toggle={() => this.toggledropdownarea("isopendropdowntype")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                          {this.state.type1dropdowntext}
                          </DropdownToggle>
                        <DropdownMenu>
                          {this.rendertypelistdropdownmenu()}
                        </DropdownMenu>
                      </Dropdown> */}
                      </FormGroup>
                    </Col>


                    
                  </Row>               
                  <Row>
                  <Col md="3">
                      <FormGroup>
                      <Input
                           name="volumenumberinput1"
                           id="volumenumberinput1"
                           placeholder="Volume"
                           type="number"
                           className="textcolorblack"
                           min="0"
                           value={this.state.volumenumberinput1}
                           invalid={this.state.volumenumber2validation}
                           onChange={(e) => this.handleChange(e.target.value,"volumenumberinput1")}/>
                          <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="9">
                      <FormGroup>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        noOptionsText={"Please complete above fill first"}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext1:value})}
                        disableClearable={true}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.type2validation} helperText={this.state.type2validationmessage} />}
                      />
                      {/* <Dropdown size="lg" className = "btn-block" block={true} isOpen={this.state.isopendropdowntype1} toggle={() => this.toggledropdownarea("isopendropdowntype1")}>
                        <DropdownToggle caret className = "btn-block" block={true}>
                        {this.state.type1dropdowntext1}
                          </DropdownToggle>
                        <DropdownMenu>
                            {this.rendertypelistdropdownmenu1()}
                        </DropdownMenu>
                      </Dropdown> */}
                      </FormGroup>
                    </Col>
                  </Row>
                  {this.state.rowvolume1?
                  <Row>
                  <Col md="3">
                      <FormGroup>
                      <Input
                          name="volumenumberinput2"
                          id="volumenumberinput2"
                          placeholder="Volume"
                          type="number"
                          className="textcolorblack"
                          min="0"
                          value={this.state.volumenumberinput2}
                          invalid={this.state.volumenumber3validation}
                          onChange={(e) => this.handleChange(e.target.value,"volumenumberinput2")}/>
                        <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="9">
                      <FormGroup>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext2:value})}
                        noOptionsText={"Please complete above fill first"}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined" error={this.state.type3validation} helperText={this.state.type3validationmessage} />}
                      />
                      </FormGroup>
                    </Col>


                    
                  </Row>               
                  :
                  null
                  }

                  {this.state.rowvolume2?
                  <Row>
                  <Col md="3">
                      <FormGroup>
                      <Input
                            name="volumenumberinput3"
                            id="volumenumberinput3"
                            placeholder="Volume"
                            type="number"
                            className="textcolorblack"
                            min="0"
                            value={this.state.volumenumberinput3}
                            invalid={this.state.volumenumber4validation}
                            onChange={(e) => this.handleChange(e.target.value,"volumenumberinput3")}/>
                            <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                      
                    </Col>
                    <Col md="9">
                      <FormGroup>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        noOptionsText={"Please complete above fill first"}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext3:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.type4validation} helperText={this.state.type4validationmessage} />}
                        disableClearable={true}
                      />
                      </FormGroup>
                    </Col>
                  </Row>
                  :
                  null
                    }

                  {this.state.rowvolume3?
                        <Row>
                        <Col md="3">
                            <FormGroup>
                            <Input
                            className="textcolorblack"
                                name="volumenumberinput4"
                                id="volumenumberinput4"
                                placeholder="Volume"
                                type="number"
                                min="0"
                                value={this.state.volumenumberinput4}
                                invalid={this.state.volumenumber5validation}
                                onChange={(e) => this.handleChange(e.target.value,"volumenumberinput4")}/>
                              <FormFeedback invalid>Please fill in the detail</FormFeedback>
                            </FormGroup>
                          </Col>
                          <Col md="9">
                            <FormGroup>
                            <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        noOptionsText={"Please complete above fill first"}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext4:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.type5validation} helperText={this.state.type5validationmessage} />}
                        disableClearable={true}
                     />
                            </FormGroup>
                          </Col>


                          
                        </Row>               
                  :
                  null
                    }

                  {this.state.rowvolume4?
           <Row>
           <Col md="3">
               <FormGroup>
               <Input
                    name="volumenumberinput5"
                    className="textcolorblack"
                    id="volumenumberinput5"
                    placeholder="Volume"
                    type="number"
                    min="0"
                    value={this.state.volumenumberinput5}
                    invalid={this.state.volumenumber6validation}
                    onChange={(e) => this.handleChange(e.target.value,"volumenumberinput5")}/>
                     <FormFeedback invalid>Please fill in the detail</FormFeedback>
               </FormGroup>
             </Col>
             <Col md="9">
               <FormGroup>
               <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        noOptionsText={"Please complete above fill first"}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext5:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.type6validation} helperText={this.state.type6validationmessage} />}
                        disableClearable={true}
                     />
               </FormGroup>
             </Col>
           </Row>
                  :
                  null
                    }

                {this.state.rowvolume5?
                  <Row>
                                  <Col md="3">
                                      <FormGroup>
                                      <Input
                                          name="volumenumberinput6"
                                          className="textcolorblack"
                                          id="volumenumberinput6"
                                          placeholder="Volume"
                                          type="number"
                                          min="0"
                                          value={this.state.volumenumberinput6}
                                          invalid={this.state.volumenumber7validation}
                                          onChange={(e) => this.handleChange(e.target.value,"volumenumberinput6")}/>
                                         <FormFeedback invalid>Please fill in the detail</FormFeedback>
                                      </FormGroup>
                                    </Col>
                                    <Col md="9">
                                      <FormGroup>
                                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        noOptionsText={"Please complete above fill first"}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext6:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.type7validation} helperText={this.state.type7validationmessage} />}
                        disableClearable={true}
                      />
                                      </FormGroup>
                                    </Col>


                                    
                                  </Row>                         
                :
                null
                  }

                {this.state.rowvolume6?
                <Row>
                <Col md="3">
                    <FormGroup>
                    <Input
                         name="volumenumberinput7"
                         id="volumenumberinput7"
                         placeholder="Volume"
                         type="number"
                         min="0"
                         className="textcolorblack"
                         value={this.state.volumenumberinput7}
                         invalid={this.state.volumenumber8validation}
                         onChange={(e) => this.handleChange(e.target.value,"volumenumberinput7")}/>
                          <FormFeedback invalid>Please fill in the detail</FormFeedback>
                    </FormGroup>
                  </Col>
                  <Col md="9">
                    <FormGroup>
                    <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        noOptionsText={"Please complete above fill first"}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext7:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.type8validation} helperText={this.state.type8validationmessage}/>}
                        disableClearable={true}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                :
                null
                }
                {this.state.rowvolume7?
                <Row>
                <Col md="3">
                    <FormGroup>
                    <Input
                        name="volumenumberinput8"
                        className="textcolorblack"
                        id="volumenumberinput8"
                        placeholder="Volume"
                        type="number"
                        min="0"
                        value={this.state.volumenumberinput8}
                        invalid={this.state.volumenumber9validation}
                        onChange={(e) => this.handleChange(e.target.value,"volumenumberinput8")}/>
                       <FormFeedback invalid>Please fill in the detail</FormFeedback>
                    </FormGroup>

                  </Col>
                  <Col md="9">
                    <FormGroup>
                    <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        noOptionsText={"Please complete above fill first"}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext8:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.type9validation} helperText={this.state.type9validationmessage} />}
                        disableClearable={true}
                      />
                    </FormGroup>
                  </Col>


                  
                </Row>               
                :
                null
                }
                {this.state.rowvolume8?
                  <Row>
                  <Col md="3">
                      <FormGroup>
                      <Input
                           name="volumenumberinput9"
                           id="volumenumberinput9"
                           placeholder="Volume"
                           type="number"
                           min="0"
                           className="textcolorblack"
                           value={this.state.volumenumberinput9}
                           invalid={this.state.volumenumber10validation}
                           onChange={(e) => this.handleChange(e.target.value,"volumenumberinput9")}/>
                            <FormFeedback invalid>Please fill in the detail</FormFeedback>
                      </FormGroup>
                    </Col>
                    <Col md="9">
                      <FormGroup>
                      <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.typelist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        noOptionsText={"Please complete above fill first"}
                        onInputChange={(event,value) =>this.setState({type1dropdowntext9:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"  error={this.state.type10validation} helperText={this.state.type10validationmessage}/>}
                        disableClearable={true}
                      />
                      </FormGroup>
                    </Col>
                  </Row>
                :
                null
                }


                  <Row>
                    <Col md="12">
                      {this.state.volumeshowrow < 8?   <Button color="primary" type="button" onClick={this.addvolumebuttonclicked.bind(this)}>
                          +
                      </Button>:null}
                      {this.state.volumeshowrow ==0 ?null:    <Button color="primary" type="button" onClick={this.minusvolumebuttonclicked.bind(this)}>
                          -
                      </Button>}
                  
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  {/*ConsignmentnoRow*/}
                  
                  <Row>
                    <Col md="2">
                        <p className="h5">Consignment No:</p>
                        </Col>
                      <Col md="3">
                        <p className="h5">Container No:</p>
                          </Col>
                      <Col md="3">
                        <p className="h5">Seal No:</p>
                          </Col>
                        <Col md="">
                        <p className="h5">Request Date:</p>
                        {this.state.requestdateerrorinvalid ?
                         <p className="h5 invalid-requestdate ">(Please fill in all Request Date)</p>
                        :
                        null
                        }
                          </Col>
                          <Col md="2">
                        <FormGroup>
                          
                          <div className="custom-control custom-control-alternative custom-checkbox mb-3">
                            <input
                              className="custom-control-input"
                              id={"customChec"}
                              type="checkbox"
                              checked={this.state.samedate}
                              onChange={(e)=>this.sameallthedate()}
                            />
                            <label className="custom-control-label" htmlFor={"customChec"}>
                              Same Date
                            </label>
                          </div>
                        </FormGroup>
                        </Col>
                        <Col md="1">
                        <p className="h5">Side</p>         
                        <p className="h5">Loader:</p>                 
                          </Col>
                    </Row>
                    {this.renderaddinvolumerow()}

                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                    <p className="h3"> </p>
                    </Col>
                  </Row>
                   
                  
                  {/*Vessel*/}
                  <Row>
                  <Col md="6">
                      <p className="h5">Vessel Name:</p>
                      <FormGroup>
                     
                      <Autocomplete
                          id="Vesselnameinput1"
                          freeSolo   
                          autoSelect      

                          style={{ flex:1,display:"block" ,marginTop:-18}}
                          options={this.state.vesselnamerecentlist.map((option) => option.title)}
                          onInputChange={(event,value) =>this.setState({vesselname:value})}
                          renderInput={(params) => (
                            <TextField {...params} label="" margin="normal" variant="outlined" error={this.state.shipmenttypevalidation} helperText={this.state.shipmenttypevalidationmessage}  />
                          )}/>
                       
                      {/* <Input
                          id="Vesselnameinput1"
                          className="textcolorblack"
                          placeholder="Vessel Name"
                          type="text"
                          value={this.state.vesselname}
                          onChange={(e)=>this.setState({vesselname:e.target.value})}
                          invalid={this.state.vesselnamevalidation}
                        /> */}
                        {/* <FormFeedback invalid>Please fill in the detail</FormFeedback> */}
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <p className="h5">Vessel Voyage:</p>
                      <FormGroup>
                      <Autocomplete
                          id="Vesselvoyageinput1"
                          style={{ flex:1,display:"block" ,marginTop:-18}}
                          freeSolo={true}
                          autoSelect
                          options={this.state.vesselvoyagerecentlist.map((option) => option.title)}
                          onInputChange={(event,value) =>this.setState({vesselvoyage:value})}
                          renderInput={(params) => (
                            <TextField {...params} label="" margin="normal" variant="outlined" error={this.state.vesselvoyagevalidation} helperText={this.state.vesselvoyagevalidationmessage}  />
                          )}
                        />
                      {/* <Input
                          id="Vesselvoyageinput1"
                          className="textcolorblack"
                          placeholder="Vessel Voyage"
                          type="text"
                          value={this.state.vesselvoyage}
                          onChange={(e)=>this.setState({vesselvoyage:e.target.value})}
                          invalid={this.state.vesselvoyagevalidation}
                        /> */}
                        {/* <FormFeedback invalid>Please fill in the detail</FormFeedback> */}
                      </FormGroup>
                    </Col>



            </Row>
                  
                  {/*Commodity*/}
                  <Row>
            <Col md="6">
                <p className="h5">Commodity:</p>
                <FormGroup>
                <Autocomplete
                          id="Commodity"
                          style={{ flex:1,display:"block" ,marginTop:-18}}
                          options={this.state.commodityrecentlist.map((option) => option.title)}
                          freeSolo={true}
                          autoSelect
                          onInputChange={(event,value) =>this.setState({commodity:value})}
                          renderInput={(params) => (
                            <TextField {...params} label="" margin="normal" variant="outlined"   />
                          )}
                        />
                {/* <Input
                    id="Commodityinput1"
                    className="textcolorblack"
                    placeholder="Commodity"
                    type="text"
                    value={this.state.commodity}
                    onChange={(e)=>this.setState({commodity:e.target.value})}
                    invalid={this.state.commodityvalidation}
                  /> */}
                  {/* <FormFeedback invalid>Please fill in the detail</FormFeedback> */}
                </FormGroup>
              </Col>
              <Col md="6">
                <p className="h5">Forwarding Agent:</p>
                <FormGroup>
                <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.forwardingagentlist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" }}
                        onInputChange={(event,value) =>this.setState({forwardingagentdropdowntext:value})}
                        renderInput={(params) => <TextField {...params} label="" variant="outlined"   error={this.state.forwardingvalidation} helperText={this.state.forwardingvalidationmessage}/>}
                        disableClearable={true}
                     />
                {/* <Dropdown className = "btn-block" block={true} isOpen={this.state.isopendropdownforwardingagent} toggle={() => this.toggledropdownarea("isopendropdownforwardingagent")}>
                  <DropdownToggle  caret   className = "btn-block" block={true}>
                    {this.state.forwardingagentdropdowntext}
                    </DropdownToggle>
                  <DropdownMenu >
                    {this.renderforwardingagentrow()}
                  </DropdownMenu>
                </Dropdown> */}
                </FormGroup>
              </Col>



            </Row>
                  
                  {/*Remark*/}
                  <Row>
            <Col md="12">
                <p className="h5">Remark:</p>
                <FormGroup>
                <Input
                    id="Remarkinput1"
                    placeholder="Remark"
                    rows="5"
                    className="textcolorblack"
                    type="textarea"
                    value={this.state.remark}
                    onChange={(e)=>this.setState({remark:e.target.value})}
                  />
                </FormGroup>
              </Col>
            
            </Row>
                  
                  {/*Attached button*/}
                 <Row>
                   <Col md="12">
                   <p className="h5">Attachment:</p> 
                   </Col>
                 </Row>
                  <Row>                 
                
                      <Col md="5">
                          
                      <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile1} />       
                       
                     
                       </Col>
                       <Col md="1">
                          
                       <p className="h5">{this.state.percentupload1}%</p>    
                       
                     
                       </Col>
                       <Col md="3">
                         {this.state.selectedFilename1 == "" ?
                         null :
                         <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename1, "_blank")}>
                       Preview
                   </Button> 
                   }
                       
                      
                        
                          </Col>
                      
                    
                 </Row>
                 {this.state.fileuploadnumber2 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile2} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload2}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename2 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename2, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber3 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile3} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload3}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename3 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename3, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber4 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile4} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload4}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename4 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename4, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber5 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile5} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload5}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename5 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename5, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber6 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile6} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload6}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename6 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename6, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber7 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile7} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload7}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename7 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename7, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber8 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile8} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload8}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename8 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename8, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber9 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile9} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload9}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename9 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename9, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                         <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                   {this.state.fileuploadnumber10 == true?
                  <Row>
                               
                               <Col md="5">
                          
                          <Input className="rowbackgroundcolorinsideexpand" type="file" name="file"  id="exampleFile" onChange={this.uploadfile10} />       
                           
                         
                           </Col>
                           <Col md="1">
                              
                           <p className="h5">{this.state.percentupload10}%</p>    
                           
                         
                           </Col>
                           <Col md="3">
                             {this.state.selectedFilename10 == "" ?
                             null :
                             <Button  color="primary" type="button" onClick={()=> window.open(this.state.selectedFilename10, "_blank")}>
                           Preview
                       </Button> 
                       }
                           
                          
                            
                              </Col>
                      <Col md="1">
                       <button className="buttonwidth" color="danger" type="button" onClick={this.buttoncancelattachmentclick.bind(this)}>
                             <span className="mb-0 text-sm">
                          X
                     </span>
                      </button>      
                       </Col>
                  </Row>
                 :
                 null
                 }
                 <Row className="mt-2">
                 <Col md="12">
                 
                  <button className="buttonwidth" color="primary" type="button" onClick={(e) => this.buttonaddattachmentclick()}>
                             <span className="mb-0 text-sm">
                          +
                     </span>
                      </button>
                      <span className="mb-0 text-sm">
                          Add More Attachment
                     </span>
                     
                    </Col>  
                 </Row>
                 
        </Form>
          </div>

          <div className="modal-footer">
      
            <Button color="primary" type="button" onClick={(e)=>this.submitnewrotforpending()}>
              Submit
            </Button>

            {/* <Button color="primary" type="button" onClick={(e)=>this.uploadfilefrom1to10()}>
              testingbutton
            </Button> */}
          </div>
           
          </CardBody>
              }

                     
         <div style={{ display: "none" }}><ComponentToPrint2 
                    volume1={this.state.volumenumberinput + "x " + this.state.type1dropdowntext}
                    volume2={this.state.volumenumberinput1 == 0? "": this.state.volumenumberinput1 + "x " + this.state.type1dropdowntext1}
                    volume3={this.state.volumenumberinput2 == 0? "": this.state.volumenumberinput2 + "x " + this.state.type1dropdowntext2}
                    volume4={this.state.volumenumberinput3 == 0? "": this.state.volumenumberinput3 + "x " + this.state.type1dropdowntext3}
                    size1=""
                    size2=""
                    size3=""
                    size4=""
                    type1=""
                    type2=""
                    type3=""
                    type4=""
                    container1={this.state["checksideloader0"] == true ?  this.state["container0"] +"(SL)" : this.state["container0"]}
                    container2={this.state["checksideloader1"] == true ?  this.state["container1"] +"(SL)" : this.state["container1"]}
                    container3={this.state["checksideloader2"] == true ?  this.state["container2"] +"(SL)" : this.state["container2"]}
                    container4={this.state["checksideloader3"] == true ?  this.state["container3"] +"(SL)" : this.state["container3"]}
                    container5={this.state["checksideloader4"] == true ?  this.state["container4"] +"(SL)" : this.state["container4"]}
                    container6={this.state["checksideloader5"] == true ?  this.state["container5"] +"(SL)" : this.state["container5"]}
                    container7={this.state["checksideloader6"] == true ?  this.state["container6"] +"(SL)" : this.state["container6"]}
                    container8={this.state["checksideloader7"] == true ?  this.state["container7"] +"(SL)" : this.state["container7"]}
                    remark={this.state.remark}
                    importexport={this.state.shipmenttypeadropdowntext}
                    shippingline={this.state.operatoreadropdowntext}
                    forwardingagent={this.state.forwardingagentdropdowntext}
                    forwardingpic={this.state.forwardingpicname}
                    commodity={this.state.commodity}
                    pod={this.state.podtext}
                    pol={this.state.poltext}
                    vesselvoyage={this.state.vesselvoyage}
                    vesselname={this.state.vesselname}
                    vesseleta={this.state.vsltoshow}
                    closingdate={this.state.closingdateshow}
                    containerrequiredate={this.state.requestdatetoshow0}
                    containerrequiretime=""
                    Bookingno={this.state.bookingno}
                    pickupyard={this.state.pickupdropdowntext}
                    returnyard={this.state.returnyarddropdowntext}
                    shippername={this.state.areadropdowntext}
                    pic={this.state.picvalue}
                    shipperaddress={this.state.deliveryaddress}
                    billingto={this.state.Billtoparty}
                    contactpic={this.state.contactvalue}
                    rotnumber={this.state.rotnumbercomeback}                    
                    consignmentlist={this.state.consignmentlist}
                    containerlist={this.state.containerlist}                      

                    ref={el => (this.componentRef = el)} /></div> 

                    


              </Card>
            </div>
          </Row>
        </Container>
     
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />

     
       
      </>
    );
  }

}


class ComponentToPrint2 extends React.Component {


  
  state= {  
    
    printbilltoparty2:this.props.billingto,
    printbookingno2:this.props.Bookingno,
    printclosing2:this.props.closingdate,
    printcommodity2:this.props.commodity,
    printcontainer12:this.props.container1,
    printcontainer22:this.props.container2,
    printcontainer32:this.props.container3,
    printcontainer42:this.props.container4,
    printcontainer52:this.props.container5,
    printcontainer62:this.props.container6,
    printcontainer72:this.props.container7,
    printcontainer82:this.props.container8,
    printeta2:this.props.vesseleta,
    printforwardingagent2:this.props.forwardingagent,
    printforwardingpic:this.props.forwardingpic,
    printpic2:this.props.pic,
    printpickup2:this.props.pickupyard,
    printpod2:this.props.pod,
    printpol2:this.props.pol,
    printremark2:this.props.remark,
    printrequiretime2:this.props.containerrequiretime,
    printrequiredate2:this.props.containerrequiredate,
    printshipper2:this.props.shippername,
    printshippingaddresss2:this.props.shipperaddress,
    printshippingliner2:this.props.shippingline,
    printsize12:this.props.size1,
    printsize22:this.props.size2,
    printsize32:this.props.size3,
    printsize42:this.props.size4,
    printtype12:this.props.type1,
    printtype22:this.props.type2,
    printtype32:this.props.type3,
    printtype42:this.props.type4,
    printvolume12:this.props.volume1,
    printvolume22:this.props.volume2,
    printvolume32:this.props.volume3,
    printvolume42:this.props.volume4,
    printvoyage2:this.props.vesselvoyage,
    printvesselname:this.props.vesselname,
    printexport2:this.props.importexport,
    printcontactpic2:this.props.contactpic,
    rotnumbercomeback:this.props.rotnumber,
    returnyard:this.props.returnyard,    
    consignmentlist:this.props.consignmentlist,
    containerlist:this.props.containerlist,
  }

  componentDidMount (){
    //console.log("class2")
    //console.log(this.state.consignmentlist)
    //console.log(this.state.containerlist)
    //this.getconsignmentnolistandcontainerlist()
  }

  render() {
    return (
      <div class="containerprint">
      <img 
      width="1000px"
      height="1442px"
      alt="..."
      class
      src={require("./../../assets/gif/ROTFORMnew3.jpg")}
      />
      <span class="importexportprintcss">{this.props.importexport}</span>
      <span class="linerprintcss">{this.props.Bookingno}</span>
       <span class="shippinglinerprintcss">{this.props.shippingline}</span>
       <span class="Forwardinglinerprintcss">{this.props.forwardingagent}</span>
       <span class="Forwardingpiclinerprintcss">{this.state.printforwardingpic}</span>
       <span class="Commoditylinerprintcss">{this.props.commodity}</span>
       <span class="POLlinerprintcss">{this.props.pol}</span>
       <span class="PODlinerprintcss">{this.props.pod}</span>
       <span class="Voyagelinerprintcss">{this.props.vesselname}/{this.props.vesselvoyage}</span>
       <span class="ETAlinerprintcss">{this.props.vesseleta}</span>
       <span class="Closinglinerprintcss">{this.props.closingdate}</span>
       <span class="Requirelinerprintcss">{this.props.containerrequiredate}</span>
       <span class="Requiretimelinerprintcss">{this.state.printrequiretime2}</span>
       <span class="Picklinerprintcss">{this.props.pickupyard}</span>
       <span class="returnyardcss">{this.props.returnyard}</span>
       <span class="Shipperprintcss">{this.props.shippername}</span>
       <span class="PICprintcss">{this.props.pic}</span>
       <span class="PICcontactcss">{this.props.contactpic}</span>
       <span class="shippingaddressprintcss">{this.props.shipperaddress}</span>
       <span class="Billprintcss">{this.props.billingto}</span>
       <span class="volume1printcss">{this.props.volume1}</span>
       <span class="volume2printcss">{this.props.volume2}</span>
       <span class="volume3printcss">{this.props.volume3}</span>
       <span class="volume4printcss">{this.props.volume4}</span>
       <span class="size1printcss">{this.state.printsize12}</span>
       <span class="size2printcss">{this.state.printsize22}</span>
       <span class="size3printcss">{this.state.printsize32}</span>
       <span class="size4printcss">{this.state.printsize42}</span>
       <span class="type1printcss">{this.state.printtype12}</span>
       <span class="type2printcss">{this.state.printtype22}</span>
       <span class="type3printcss">{this.state.printtype32}</span>
       <span class="type4printcss">{this.state.printtype42}</span>
       <span class="container1printcss">{this.props.containerlist[0]}</span>
       <span class="container2printcss">{this.props.containerlist[1]}</span>
       <span class="container3printcss">{this.props.containerlist[2]}</span>
       <span class="container4printcss">{this.props.containerlist[3]}</span>
       <span class="container5printcss">{this.props.containerlist[4]}</span>
       <span class="container6printcss">{this.props.containerlist[5]}</span>
       <span class="container7printcss">{this.props.containerlist[6]}</span>
       <span class="container8printcss">{this.props.containerlist[7]}</span>
       <span class="remarkprintcss">{this.props.remark}</span>
       <span class="rotnumberprintcss">Rot No:{this.props.rotnumber}</span>
         {/* Loop through container list */}
         <div className="containernocssprint">
          <span className="containernocssprintword">Container:</span>
          {this.props.containerlist.slice(8).map((container, index) => (
            <div key={index}>{container}</div>
          ))}
        </div>


        {/* Loop through consignment list */}
        <div className="consignmentnocssprint">
          CN No:
          {this.props.consignmentlist.map((consignment, index) => (
            <div key={index}>{consignment}</div>
          ))}
        </div>
      </div>
     
       
    );
  }
}

export default AddNewROT;


