/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// reactstrap components
import {
  Modal,
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Alert,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown,
} from "reactstrap";
import { Autocomplete } from '@material-ui/lab';
import TextField from '@material-ui/core/TextField';
import axios ,{post} from 'axios'; 


class Register extends React.Component {

  state = {
    isopendropdowndirect:false,
    directdropdowntext:'Forwarder',
    billtopartylist:[],
    billtopartychoosen:'',
    forwardinglist:[],
    forwardingchoosen:'',
    fullnametext:'',
    usernametext:'',
    passwordtext:'',
    confirmpasswordtext:'',
    emailtext:'',
    contacttext:'',
    checkagreeornot:false,
    alerterrormessage:'',
    errorsubmit:false,
    fullnameinvalid:false,
    billtopartyinvalid:false,
    forwardinginvalid:false,
    usernameinvalid:false,
    passwordinvalid:false,
    confirmpasswordinvalid:false,
    emailinvalid:false,
    contactinvalid:false,
    checkagreeornotinvalid:false,
    onchangeprofilepicture:'',
    profileimageurl:"https://crmpkl-mewahtrans.mewahserver.com/api/img/profile_img/0.jpg",
   // linkurl:"https://cors-anywhere.herokuapp.com/http://crmpkl-mewahtrans.mewahserver.com/api/mewahapicode.php?",
    linkurl:"https://crmpkl-mewahtrans.mewahserver.com/api/mewahapicode.php?"
  };
  

  toggledropdowndirect =  state => {
    this.setState({
      [state]:!this.state[state]
    });
  };

  componentDidMount(){
    this.setState({forwardinglist:[{title:"Forwarder"},{title:"Direct"}]})
    this.loadbilltopartylist()
  }
  loadbilltopartylist = () =>{
    
    var urlencoded = new URLSearchParams();
    let link = this.state.linkurl + 'mode=admin-getbilltopartylist'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        
            console.log(json)
            this.setState({billtopartylist:[]});   
             var lengthemployee = Object.keys(json.user).length;
             for(let i=0;i<lengthemployee;i++){             
            
              //status need do
               this.setState({billtopartylist:[...this.state.billtopartylist,
                { no:i+1,
                  billtoparty:json.user[i].billtopartyname,
                  title:json.user[i].billtopartyname
                },            
              ]})
              
              
             

             
              }
            }        
               
         else{
        console.log(json)
        lengthemployee = 0
      }
      this.setState({billtopartylist:[...this.state.billtopartylist,
        { no:lengthemployee+1,
          billtoparty:"Other",
          title:"Other"
        },            
      ]})
     })
   
  }
functionchoosebilltoparty = (value) =>{
  
  if(value == "Other"){
    this.setState({showotherbilltopartybox:true})
  }else{
    this.setState({showotherbilltopartybox:false})
    this.setState({billtopartychoosen:value})
  }
}
startuploadsaveprofileimage = ()=>{
  //update database profile image name
  //reload sessioncache 
    if(Object.keys(this.state.onchangeprofilepicture.name).length < 25){
      const formData = new FormData();    
      var timestampnow = new Date().getTime()/1000
      console.log("formData: " + formData)
      console.log("onchangeprofilepicture: " + this.state.onchangeprofilepicture)
      // Update the formData object 
        if(this.state.onchangeprofilepicture !=null){
          formData.append( 
            "myFile1", 
            this.state.onchangeprofilepicture,
          );
        }
        formData.append( 
          "timestampnow", 
          timestampnow
        );
  
        const config = {
          headers: {
              'content-type': 'multipart/form-data'
          }
      }
    
    
        return post(this.state.linkurl+"mode=admin-uploadupdateprofileimagetemporary", formData,config )
        .then(res => {

         // window.confirm(res.data.message);       
         // sessionStorage.setItem('Profilepictureurl', res.data.filelink);
          this.setState({profileimageurl:"https://crmpkl-mewahtrans.mewahserver.com/api/img/temp_img/" + res.data.filelink})
          this.toggleModal("defaultModal")
        });
    }else{
      window.confirm("Filename too long, please change your file name");     
    }
    
  }
 
  startuploadsaveprofileimagenottemporary = ()=>{
    //update database profile image name
    //reload sessioncache
        if(Object.keys(this.state.onchangeprofilepicture.name).length < 25){
        const formData = new FormData();    
   
        console.log("formData: " + formData)
        console.log("onchangeprofilepicture: " + this.state.onchangeprofilepicture)
        // Update the formData object 
          if(this.state.onchangeprofilepicture !=null){
            formData.append( 
              "myFile1", 
              this.state.onchangeprofilepicture,
            );
          }
        
    
          const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
      
      
          return post(this.state.linkurl+"mode=admin-uploadupdateprofileimageregister", formData,config )
          .then(res => {
  

          });
      }
      
    
   
  }




toggleModal = state => {
  this.setState({
    [state]: !this.state[state]
  });
};

submitregisternewaccount= () =>{
  // console.log("billtopartychoosen" + this.state.billtopartychoosen)
  // console.log("forwardingchoosen" + this.state.forwardingchoosen)
  // console.log("fullnametext" + this.state.fullnametext)
  // console.log("usernametext" + this.state.usernametext)
  // console.log("passwordtext" + this.state.passwordtext)
  // console.log("confirmpasswordtext" + this.state.confirmpasswordtext)
  // console.log("emailtext" + this.state.emailtext)
  // console.log("contacttext" + this.state.contacttext)
  // console.log("checkagreeornot" + this.state.checkagreeornot)
  if(/[!#$%^&*,.?":{}|<>]/g.test(this.state.billtopartychoosen) && this.state.showotherbilltopartybox == true) {
   this.setState({alerterrormessage:'Bill to party cannot include symbol'})
   this.setState({errorsubmit:true})
    window.scrollTo(0, 0)
  }else if(this.state.billtopartychoosen == ""){
    this.setState({alerterrormessage:'Bill to party cannot empty'})
    this.setState({errorsubmit:true})
    window.scrollTo(0, 0)
  }else if(this.state.forwardingchoosen == ""){
    this.setState({alerterrormessage:'Please choose Forwarder or Direct Customer'})
    this.setState({errorsubmit:true})
    window.scrollTo(0, 0)
  }else if(this.state.fullnametext == ""){
    this.setState({alerterrormessage:'Fullname cannot empty'})
    this.setState({errorsubmit:true})
    window.scrollTo(0, 0)
  }else if(/[!#$%^&*,.?":{}|<>]/g.test(this.state.fullnametext)){
    this.setState({alerterrormessage:'Fullname cannot contain symbol'})
    this.setState({errorsubmit:true})
    window.scrollTo(0, 0)
  }else if(this.state.passwordtext == ""){
    this.setState({alerterrormessage:'Password cannot empty'})
    this.setState({errorsubmit:true})
  }else if(/[!#$%^&*,.?":{}|<>]/g.test(this.state.passwordtext)){
    this.setState({alerterrormessage:'Password cannot contain symbol'})
    this.setState({errorsubmit:true})
    window.scrollTo(0, 0)
  }else if(this.state.passwordtext != this.state.confirmpasswordtext){
    this.setState({alerterrormessage:'Confirm password is different with password'})
    this.setState({errorsubmit:true})
    window.scrollTo(0, 0)
  }else if(this.state.emailtext == ""){
    this.setState({alerterrormessage:'Email cannot empty'})
    this.setState({errorsubmit:true})
    window.scrollTo(0, 0)
  }else if(this.state.emailtext == "" || !this.state.emailtext.includes("@")){
    this.setState({alerterrormessage:'Invalid email'})
    this.setState({errorsubmit:true})
    window.scrollTo(0, 0)
  }else if(/^[a-z]/.test(this.state.contacttext) || /^[A-Z]/.test(this.state.contacttext)){
    this.setState({alerterrormessage:'Invalid contact'})
    this.setState({errorsubmit:true})
    window.scrollTo(0, 0)
  }else if(this.state.checkagreeornot != true){
    this.setState({alerterrormessage:'Please check the agree check box'})
    this.setState({errorsubmit:true})
    window.scrollTo(0, 0)
  }else{
    this.setState({loading:true}) 
    var urlencoded = new URLSearchParams();
    urlencoded.append("billtopartychoosen", this.state.billtopartychoosen);
    urlencoded.append("forwardingchoosen", this.state.forwardingchoosen);
    urlencoded.append("fullnametext", this.state.fullnametext);
    urlencoded.append("usernametext", this.state.emailtext);
    urlencoded.append("passwordtext", this.state.passwordtext);
    urlencoded.append("confirmpasswordtext", this.state.confirmpasswordtext);
    urlencoded.append("emailtext", this.state.emailtext);
    urlencoded.append("contacttext", this.state.contacttext);
  
    let link = this.state.linkurl + 'mode=admin-registernewaccount'
    fetch(link, {
      method: 'POST', 
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body:urlencoded
    })
    .then(response => response.json())
    .then(json => {
      console.log(json.success)
      if(json.success == 1){
        if(this.state.onchangeprofilepicture != ''){
          this.startuploadsaveprofileimagenottemporary();
        }
        if(window.confirm('Success Register As New User, Please wait admin to approve')){
          this.props.history.push({
            pathname: '/auth/login',
            state: {
              id: 0,
            }
          })
        }
        
      }
               
         else{
          this.setState({loading:false}) 
        console.log(json)
      }
    
     })
  }
  
}

  render() {
    return (
      <>
        <Col lg="6" md="8">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-1">
            <div className="text-center text-muted mb-4 ">
                <small>Sign up with credentials</small>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
            
              <Form role="form">
              {this.state.errorsubmit == true ?
                     <Alert color="danger">
                     {this.state.alerterrormessage}
                   </Alert>
                   :
                   null
                   }
                   <FormGroup>
                   <Row className="justify-content-center">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          width="80px"
                          height="80px"
                          alt="..."
                          className="rounded-circle"
                          src={this.state.profileimageurl}
                          onClick={() => this.toggleModal("defaultModal")}
                        />
                      </a>
                    </div>
                  </Col>
                
                </Row>
                <Row className="justify-content-center">
                <Button
                    color="primary"
                    data-dismiss="modal"
                    type="button"
                    className="mt-6"
                    onClick={() => this.toggleModal("defaultModal")}
                    >
                    Upload Profile Picture
                  </Button>
                  </Row>
                   </FormGroup>
              <FormGroup >
                  <InputGroup className="input-group-alternative mb-3 border border-gray mt-4">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-circle-08" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input invalid={this.state.fullnameinvalid} placeholder="Full Name" type="text" value={this.state.fullnametext} onChange = {(e) => this.setState({fullnametext: e.target.value })} />
                  </InputGroup>
                </FormGroup>


                <FormGroup>
                <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.billtopartylist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" ,backgroundColor:"white"}}
                        onInputChange={(event,value) =>this.functionchoosebilltoparty(value)}
                        renderInput={(params) => <TextField {...params} label="@ Bill to Party" variant="outlined" error={this.state.billtopartyvalidation} helperText={this.state.billtopartyvalidationmessage} />}
                        disableClearable={true}
                      />
                </FormGroup>
                {this.state.showotherbilltopartybox == true ?
                <FormGroup>
                <InputGroup className="input-group-alternative mb-3 border border-gray">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      @
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Bill To Party" type="text" value={this.state.billtopartychoosen} onChange = {(e) => this.setState({billtopartychoosen: e.target.value })} />
                </InputGroup>
              </FormGroup>
                :
                null
                }
                  <FormGroup>
                  <InputGroup className="input-group-alternative mb-3 border border-gray">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email/Username" type="email" value={this.state.emailtext} onChange = {(e) => this.setState({emailtext: e.target.value })}/>
                  </InputGroup>
                </FormGroup>
                {/* <FormGroup>
                  <InputGroup className="input-group-alternative mb-3 border border-gray">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Username" type="text" value={this.state.usernametext} onChange = {(e) => this.setState({usernametext: e.target.value })} />
                  </InputGroup>
                </FormGroup> */}
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3 border border-gray">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type="password" value={this.state.passwordtext} onChange = {(e) => this.setState({passwordtext: e.target.value })}/>
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3 border border-gray">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-key-25" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Confirm Password" type="password" value={this.state.confirmpasswordtext} onChange = {(e) => this.setState({confirmpasswordtext: e.target.value })} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                <Autocomplete
                        id="combo-box-demo2"
                        options={this.state.forwardinglist}
                        getOptionLabel={(option) => option.title}                       
                        style={{ flex:1,display:"block" ,backgroundColor:"white"}}
                        onInputChange={(event,value) =>this.setState({forwardingchoosen:value})}
                        renderInput={(params) => <div><TextField {...params} label="+ Forwarder/Direct Customer" variant="outlined" error={this.state.forwardervalidation} helperText={this.state.forwardervalidationmessage} /> </div>}
                        disableClearable={true}
                      />
                </FormGroup>
              
              
                {/* <FormGroup>
                  <InputGroup className="input-group-alternative mb-3 border border-gray">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="email" value={this.state.emailtext} onChange = {(e) => this.setState({emailtext: e.target.value })}/>
                  </InputGroup>
                </FormGroup> */}
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3 border border-gray">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-mobile-button" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Contact" type="text" value={this.state.contacttext} onChange = {(e) => this.setState({contacttext: e.target.value })} />
                  </InputGroup>
                </FormGroup>
              
           
                <Row className="my-4">
                  <Col xs="12">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                      className="custom-control-input"
                      id="customCheck2"
                      type="checkbox"
                      disabled={false}
                      checked={this.state.checkagreeornot}
                      onChange={(e)=> this.setState({checkagreeornot:!this.state.checkagreeornot}) }
                    />  
                      <label className="custom-control-label" htmlFor={"customCheck2"}>
                        I agree to register new account
                      </label>
                    </div>
                  </Col>
                </Row>
                <div className="text-center">
                  <Button className="mt-4" color="primary" type="button" onClick={(e) => this.submitregisternewaccount()}>
                    Create account
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            
            <Col xs="6">
              <a
                className="text-light"
                href="/auth/login"
                to="/auth/login"
                tag={Link}
              >
                <small> Back to Login</small>
              </a>
            </Col>
           
        
          </Row>
        </Col>

        <Modal
              className="modal-dialog-centered"
              isOpen={this.state.defaultModal}
              toggle={() => this.toggleModal("defaultModal")}
            >
              <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">
                  Edit Profile Picture
                </h6>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("defaultModal")}
                >
                  <span aria-hidden={true}>×</span>
                </button>
              </div>
              <div className="modal-body">
             
              {/* <Row>
                               <Col md="12">
                          
                                <img
                                width="100px"
                                height="100px"
                                  alt="..."
                                  className="rounded-circle img-center"
                                  //src={this.state.onchangeprofilepicture}
                                  src={require('D:\self work\pumpkin.jpg')}
                                />
                            
                               </Col>
                              
                               </Row> */}
                               <Row>
                               <Col md="12">      
                                                    
                           <Input className="mt-2 rowbackgroundcolorinsideexpand img-center" accept=".jpg,.png" type="file" name="file"  id="exampleFile" onChange={(e) => this.setState({onchangeprofilepicture:e.target.files[0]})} />                         
                             </Col>
                                 
                                 </Row>

              </div>
              <div className="modal-footer">
                <Button color="primary" type="button" onClick={() => this.startuploadsaveprofileimage()}>
                  Save changes
                </Button>
                <Button
                  className="ml-auto"
                  color="link"
                  data-dismiss="modal"
                  type="button"
                  onClick={() => this.toggleModal("defaultModal")}
                >
                  Close
                </Button>
              </div>
            </Modal>
      </>
    );
  }
}

export default Register;
